const imFrames = [];

imFrames[0] = [
	[536, 1117, 185, 229, 0, -48, -42],
	[787, 227, 162, 163, 0, -69, -76],
	[776, 422, 199, 209, 0, -47, -56],
	[687, 1557, 276, 184, 0, -14, -57],
	[730, 1268, 225, 176, 0, -37, -72],
	[762, 1, 216, 224, 0, -44, -32],
	[472, 1, 288, 142, 0, -7, -69],
	[499, 259, 286, 161, 0, -7, -67],
	[1, 1, 168, 297, 0, -54, -3],
	[1, 868, 292, 200, 0, -5, -47],
	[1, 300, 202, 288, 0, -51, -8],
	[137, 728, 224, 138, 0, -50, -93],
	[494, 446, 280, 235, 0, -11, -37],
	[1, 1070, 245, 239, 0, -41, -43],
	[205, 294, 292, 150, 0, -5, -78],
	[295, 868, 263, 247, 0, -27, -38],
	[426, 683, 245, 172, 0, -47, -63],
	[1, 590, 134, 276, 0, -84, -17],
	[137, 609, 287, 117, 0, -6, -96],
	[1, 1311, 168, 236, 0, -69, -43],
	[171, 1312, 275, 211, 0, -17, -45],
	[673, 683, 224, 144, 0, -39, -88],
	[472, 145, 288, 112, 0, -5, -96],
	[560, 857, 280, 224, 0, -19, -38],
	[248, 1117, 286, 193, 0, -5, -54],
	[171, 1, 299, 225, 0, 0, -46],
	[205, 446, 287, 161, 0, -7, -79],
	[1, 1549, 124, 127, 0, -88, -79],
	[171, 228, 203, 64, 0, -63, -112],
	[408, 1557, 277, 190, 0, -7, -72],
	[723, 1083, 201, 183, 0, -60, -71],
	[171, 1525, 235, 198, 0, -35, -52],
	[448, 1348, 280, 207, 0, -11, -32]
];
imFrames[2] = [
	[1, 286, 284, 205, 0, -8, -52],
	[289, 222, 285, 94, 0, -6, -101],
	[357, 1371, 121, 253, 0, -88, -26],
	[1, 493, 254, 267, 0, -26, -16],
	[287, 318, 272, 234, 0, -23, -36],
	[222, 1089, 188, 280, 0, -54, -15],
	[1, 1371, 176, 257, 0, -62, -26],
	[1, 1, 295, 155, 0, -2, -70],
	[179, 1371, 176, 254, 0, -56, -26],
	[593, 1, 102, 260, 0, -92, -24],
	[1, 1035, 219, 237, 0, -36, -47],
	[298, 1, 293, 219, 0, -4, -47],
	[1, 158, 286, 126, 0, -6, -86],
	[412, 1101, 153, 252, 0, -81, -29],
	[257, 554, 243, 153, 0, -30, -72],
	[502, 556, 190, 284, 0, -69, -9],
	[237, 819, 226, 268, 0, -35, -21],
	[576, 263, 137, 291, 0, -83, -5],
	[465, 842, 216, 257, 0, -37, -27],
	[480, 1355, 178, 269, 0, -62, -17],
	[257, 709, 240, 108, 0, -27, -101],
	[1, 762, 234, 271, 0, -31, -11]
];
imFrames[1] = [
	[1, 572, 260, 290, 0, -16, -10],
	[1168, 268, 272, 196, 0, -15, -42],
	[778, 797, 177, 151, 0, -59, -74],
	[1, 864, 270, 123, 0, -12, -94],
	[690, 247, 247, 265, 0, -33, -21],
	[579, 1, 293, 244, 0, -3, -31],
	[500, 264, 188, 287, 0, -54, -8],
	[939, 461, 217, 152, 0, -45, -83],
	[1, 272, 254, 298, 0, -34, -1],
	[893, 615, 290, 180, 0, -4, -58],
	[263, 567, 267, 273, 0, -15, -12],
	[1425, 813, 264, 106, 0, -20, -90],
	[957, 797, 172, 177, 0, -74, -66],
	[1445, 213, 278, 227, 0, -8, -25],
	[794, 514, 97, 279, 0, -110, -12],
	[1425, 631, 292, 180, 0, -4, -50],
	[273, 842, 243, 147, 0, -27, -85],
	[1131, 823, 165, 157, 0, -74, -82],
	[874, 1, 294, 236, 0, -4, -27],
	[532, 787, 244, 125, 0, -34, -107],
	[1158, 466, 213, 147, 0, -41, -83],
	[1170, 1, 273, 265, 0, -12, -23],
	[1445, 1, 286, 210, 0, -9, -41],
	[1185, 615, 238, 206, 0, -37, -47],
	[939, 239, 227, 220, 0, -38, -41],
	[1, 1, 292, 269, 0, -4, -17],
	[1442, 442, 273, 187, 0, -18, -63],
	[257, 272, 241, 293, 0, -32, -4],
	[295, 1, 282, 261, 0, -8, -25],
	[532, 553, 260, 232, 0, -15, -35]
];
imFrames[3] = [
	[1, 1, 296, 54, 0, -2, -122],
	[287, 195, 283, 136, 0, -8, -82],
	[413, 1527, 210, 234, 0, -55, -58],
	[278, 743, 249, 262, 0, -27, -22],
	[1, 1030, 243, 251, 0, -36, -33],
	[278, 606, 264, 135, 0, -16, -86],
	[282, 333, 277, 271, 0, -11, -19],
	[495, 1142, 237, 284, 0, -24, -7],
	[561, 427, 270, 207, 0, -13, -55],
	[853, 247, 124, 278, 0, -86, -12],
	[225, 1530, 186, 261, 0, -71, -24],
	[625, 1428, 71, 229, 0, -117, -37],
	[586, 1, 283, 234, 0, -4, -42],
	[625, 1659, 192, 154, 0, -59, -77],
	[299, 1, 285, 192, 0, -5, -61],
	[698, 1461, 117, 165, 0, -85, -67],
	[777, 1055, 223, 284, 0, -46, -9],
	[803, 812, 204, 241, 0, -63, -35],
	[544, 636, 257, 215, 0, -15, -20],
	[1, 559, 275, 216, 0, -19, -54],
	[572, 237, 279, 188, 0, -12, -67],
	[819, 1461, 184, 179, 0, -58, -62],
	[1, 57, 284, 253, 0, -13, -27],
	[833, 527, 174, 283, 0, -52, -10],
	[529, 853, 246, 287, 0, -32, -9],
	[248, 1007, 245, 246, 0, -25, -27],
	[246, 1255, 237, 270, 0, -21, -16],
	[1, 312, 279, 245, 0, -5, -18],
	[1, 777, 245, 251, 0, -29, -24],
	[1, 1530, 222, 278, 0, -34, -15],
	[1, 1283, 235, 245, 0, -33, -27],
	[871, 1, 128, 244, 0, -89, -31],
	[734, 1341, 217, 118, 0, -77, -89]
];
imFrames[4] = [
	[1, 440, 292, 280, 0, -4, -10],
	[1, 1030, 287, 233, 0, -8, -41],
	[297, 272, 294, 142, 0, -3, -80],
	[295, 593, 291, 158, 0, -4, -69],
	[579, 1150, 286, 136, 0, -9, -76],
	[1, 1, 295, 176, 0, -3, -62],
	[593, 289, 254, 296, 0, -18, -2],
	[297, 416, 293, 175, 0, -3, -45],
	[1, 849, 290, 179, 0, -6, -47],
	[1, 1373, 148, 288, 0, -76, -9],
	[298, 1, 295, 175, 0, -2, -57],
	[586, 872, 284, 140, 0, -9, -77],
	[1, 179, 294, 259, 0, -3, -18],
	[290, 1095, 287, 142, 0, -8, -79],
	[294, 753, 290, 183, 0, -6, -64],
	[585, 1014, 285, 134, 0, -8, -90],
	[595, 1, 274, 286, 0, -16, -8],
	[663, 1288, 201, 281, 0, -46, -13],
	[1, 1265, 279, 106, 0, -8, -79],
	[1, 722, 291, 125, 0, -6, -93],
	[240, 1472, 192, 155, 0, -54, -75],
	[151, 1373, 87, 282, 0, -110, -12],
	[298, 178, 295, 92, 0, -3, -98],
	[592, 587, 240, 283, 0, -35, -13],
	[434, 1472, 227, 149, 0, -41, -85],
	[293, 938, 290, 155, 0, -3, -74],
	[290, 1239, 280, 231, 0, -5, -36]
];
imFrames[5] = [
	[1, 1, 253, 283, 0, -24, -12],
	[1, 529, 225, 290, 0, -40, -5],
	[819, 405, 281, 87, 0, -7, -112],
	[1356, 590, 130, 283, 0, -86, -10],
	[1198, 590, 156, 253, 0, -73, -26],
	[470, 526, 236, 229, 0, -34, -46],
	[1195, 298, 171, 290, 0, -68, -6],
	[737, 1, 175, 197, 0, -67, -49],
	[284, 265, 242, 259, 0, -33, -26],
	[908, 494, 143, 236, 0, -60, -11],
	[974, 732, 222, 288, 0, -38, -7],
	[256, 1, 249, 262, 0, -25, -21],
	[1, 821, 289, 184, 0, -4, -57],
	[708, 521, 198, 216, 0, -55, -58],
	[1430, 1, 62, 255, 0, -119, -26],
	[1063, 1, 206, 295, 0, -47, -2],
	[785, 739, 187, 246, 0, -65, -27],
	[1053, 494, 140, 222, 0, -84, -39],
	[228, 529, 240, 267, 0, -29, -21],
	[575, 757, 208, 233, 0, -50, -33],
	[528, 244, 292, 159, 0, -4, -79],
	[1368, 283, 124, 284, 0, -84, -6],
	[528, 405, 289, 114, 0, -4, -97],
	[507, 1, 228, 241, 0, -32, -23],
	[822, 200, 239, 119, 0, -60, -72],
	[1271, 1, 157, 280, 0, -78, -11],
	[1, 286, 281, 241, 0, -7, -34],
	[292, 798, 281, 187, 0, -11, -56]
];
imFrames[6] = [
	[1, 1353, 154, 289, 0, -78, -6],
	[157, 1353, 218, 282, 0, -43, -10],
	[377, 1318, 221, 280, 0, -34, -12],
	[278, 417, 272, 165, 0, -12, -88],
	[869, 1, 122, 284, 0, -87, -12],
	[1, 1, 293, 168, 0, -3, -68],
	[566, 325, 272, 277, 0, -12, -14],
	[270, 584, 265, 166, 0, -21, -72],
	[1, 535, 267, 250, 0, -21, -30],
	[289, 208, 275, 207, 0, -12, -44],
	[537, 717, 255, 284, 0, -30, -9],
	[1, 1075, 224, 276, 0, -38, -11],
	[794, 837, 188, 223, 0, -61, -41],
	[585, 105, 277, 218, 0, -11, -38],
	[1, 407, 275, 126, 0, -15, -91],
	[585, 1, 282, 102, 0, -13, -117],
	[1, 787, 238, 286, 0, -32, -9],
	[600, 1316, 219, 280, 0, -37, -12],
	[537, 604, 257, 111, 0, -22, -97],
	[763, 1062, 230, 252, 0, -40, -33],
	[1, 171, 286, 234, 0, -5, -27],
	[840, 325, 161, 281, 0, -70, -11],
	[296, 1, 287, 205, 0, -5, -46],
	[821, 1316, 172, 277, 0, -62, -11],
	[796, 608, 200, 227, 0, -50, -41],
	[523, 1003, 238, 270, 0, -34, -16],
	[241, 1041, 225, 275, 0, -37, -15],
	[270, 752, 251, 287, 0, -22, -7]
];
imFrames[8] = [
	[233, 540, 278, 193, 0, -13, -63],
	[561, 1, 269, 263, 0, -19, -21],
	[832, 1, 166, 271, 0, -63, -13],
	[758, 555, 208, 257, 0, -46, -22],
	[1, 545, 219, 224, 0, -46, -47],
	[151, 918, 284, 181, 0, -8, -71],
	[1, 1, 272, 269, 0, -16, -20],
	[275, 1, 284, 254, 0, -8, -21],
	[110, 1101, 276, 163, 0, -11, -65],
	[718, 1000, 175, 243, 0, -66, -34],
	[222, 735, 292, 181, 0, -3, -60],
	[388, 1107, 291, 112, 0, -4, -102],
	[275, 257, 243, 281, 0, -24, -11],
	[520, 503, 236, 238, 0, -31, -32],
	[1, 1062, 107, 191, 0, -96, -55],
	[520, 266, 290, 235, 0, -5, -20],
	[1, 771, 148, 289, 0, -76, -5],
	[753, 814, 252, 184, 0, -31, -82],
	[812, 274, 195, 279, 0, -57, -11],
	[516, 743, 235, 185, 0, -34, -58],
	[437, 930, 279, 175, 0, -9, -65],
	[1, 272, 230, 271, 0, -27, -18]
];
imFrames[7] = [
	[1, 1, 266, 266, 0, -17, -17],
	[1, 269, 266, 266, 0, -17, -17],
	[1, 537, 266, 266, 0, -17, -17],
	[269, 1, 266, 266, 0, -17, -17],
	[269, 269, 266, 266, 0, -17, -17],
	[269, 537, 266, 266, 0, -17, -17],
	[537, 1, 266, 266, 0, -17, -17],
	[537, 269, 266, 266, 0, -17, -17],
	[537, 537, 266, 266, 0, -17, -17],
	[805, 1, 266, 266, 0, -17, -17]
];
imFrames[9] = [
	[1439, 292, 273, 282, 0, -25, -16],
	[1716, 281, 286, 283, 0, -12, -15],
	[293, 597, 295, 263, 0, -3, -35],
	[1152, 1, 273, 290, 0, -25, -8],
	[861, 296, 279, 293, 0, -19, -5],
	[1, 1, 287, 296, 0, -11, -2],
	[884, 1, 266, 291, 0, -32, -7],
	[1716, 1, 289, 278, 0, -9, -20],
	[290, 1, 296, 295, 0, -2, -3],
	[1743, 566, 261, 285, 0, -37, -13],
	[1152, 586, 295, 281, 0, -3, -17],
	[1, 597, 290, 266, 0, -8, -32],
	[288, 299, 282, 296, 0, -16, -2],
	[1449, 576, 292, 286, 0, -6, -12],
	[1142, 294, 295, 290, 0, -3, -8],
	[861, 591, 289, 276, 0, -9, -22],
	[1, 299, 285, 296, 0, -13, -2],
	[572, 298, 287, 294, 0, -11, -4],
	[588, 1, 294, 293, 0, -4, -5],
	[1427, 1, 287, 289, 0, -11, -9]
];
imFrames[10] = [
	[707, 774, 203, 221, 0, -56, -41],
	[557, 221, 290, 187, 0, -6, -59],
	[1429, 810, 286, 154, 0, -8, -67],
	[272, 532, 243, 225, 0, -29, -45],
	[812, 638, 287, 109, 0, -5, -97],
	[282, 259, 239, 271, 0, -42, -17],
	[523, 410, 296, 180, 0, -3, -58],
	[1717, 627, 189, 208, 0, -58, -50],
	[1, 252, 279, 247, 0, -9, -30],
	[1425, 416, 191, 194, 0, -55, -56],
	[445, 774, 260, 219, 0, -23, -49],
	[1618, 489, 288, 136, 0, -8, -70],
	[1307, 681, 120, 275, 0, -90, -10],
	[1, 760, 201, 263, 0, -52, -28],
	[290, 1, 265, 256, 0, -14, -28],
	[1409, 1, 278, 200, 0, -13, -43],
	[849, 397, 276, 85, 0, -12, -104],
	[1128, 203, 296, 144, 0, -2, -82],
	[1127, 681, 178, 293, 0, -102, 0],
	[1681, 291, 243, 196, 0, -30, -53],
	[557, 1, 280, 218, 0, -10, -53],
	[839, 1, 287, 205, 0, -5, -52],
	[1426, 203, 253, 211, 0, -22, -48],
	[204, 760, 239, 244, 0, -28, -33],
	[849, 208, 276, 187, 0, -12, -49],
	[912, 749, 213, 269, 0, -79, -6],
	[1, 1, 287, 249, 0, -6, -40],
	[821, 484, 293, 152, 0, -4, -87],
	[1127, 349, 296, 144, 0, -1, -84],
	[1128, 1, 279, 200, 0, -9, -56],
	[1429, 627, 278, 181, 0, -17, -51],
	[517, 592, 293, 180, 0, -4, -75],
	[1, 501, 269, 257, 0, -6, -17],
	[1116, 495, 288, 184, 0, -5, -64],
	[1689, 1, 226, 288, 0, -67, -6]
];
imFrames[12] = [
	[695, 249, 289, 240, 0, -8, -28],
	[1667, 913, 209, 173, 0, -45, -71],
	[856, 921, 234, 186, 0, -36, -73],
	[211, 563, 251, 258, 0, -29, -24],
	[295, 823, 278, 255, 0, -14, -12],
	[1276, 219, 286, 196, 0, -8, -50],
	[1280, 575, 282, 151, 0, -8, -81],
	[575, 995, 259, 98, 0, -21, -96],
	[299, 1, 267, 265, 0, -10, -10],
	[1, 579, 208, 279, 0, -73, -15],
	[568, 1, 262, 246, 0, -15, -30],
	[1280, 417, 282, 156, 0, -14, -82],
	[986, 233, 288, 219, 0, -7, -43],
	[1, 860, 292, 247, 0, -3, -27],
	[1, 1, 143, 292, 0, -88, -5],
	[1564, 478, 292, 152, 0, -5, -78],
	[1092, 921, 291, 185, 0, -4, -60],
	[456, 268, 237, 247, 0, -53, -31],
	[832, 1, 288, 230, 0, -6, -34],
	[146, 1, 151, 280, 0, -73, -10],
	[1597, 183, 286, 175, 0, -7, -68],
	[464, 517, 292, 244, 0, -4, -33],
	[211, 283, 243, 278, 0, -24, -14],
	[1041, 454, 237, 197, 0, -27, -63],
	[1430, 728, 279, 183, 0, -15, -58],
	[1143, 728, 285, 183, 0, -7, -66],
	[1597, 1, 287, 180, 0, -7, -60],
	[1385, 913, 280, 183, 0, -8, -69],
	[1340, 1, 255, 193, 0, -24, -61],
	[1564, 360, 294, 116, 0, -4, -101],
	[856, 718, 285, 201, 0, -10, -60],
	[1, 295, 208, 282, 0, -73, -11],
	[758, 491, 281, 225, 0, -7, -42],
	[575, 763, 279, 230, 0, -12, -40],
	[1122, 1, 216, 216, 0, -48, -50]
];
imFrames[11] = [
	[569, 262, 277, 212, 0, -11, -47],
	[848, 486, 285, 200, 0, -8, -45],
	[848, 249, 282, 235, 0, -7, -31],
	[1691, 1, 211, 252, 0, -67, -5],
	[259, 674, 291, 196, 0, -2, -50],
	[277, 1, 290, 258, 0, -5, -24],
	[1132, 240, 285, 231, 0, -6, -43],
	[512, 899, 287, 170, 0, -7, -73],
	[841, 688, 286, 227, 0, -7, -39],
	[1135, 473, 260, 210, 0, -26, -41],
	[1, 836, 246, 249, 0, -22, -32],
	[1, 556, 256, 278, 0, -31, -11],
	[1678, 689, 102, 250, 0, -103, -46],
	[1421, 835, 255, 171, 0, -26, -62],
	[277, 261, 290, 192, 0, -7, -60],
	[841, 1, 276, 246, 0, -12, -32],
	[1397, 503, 274, 173, 0, -14, -63],
	[1673, 428, 139, 259, 0, -86, -27],
	[1422, 678, 207, 155, 0, -67, -67],
	[801, 917, 277, 162, 0, -11, -74],
	[1135, 685, 285, 123, 0, -10, -91],
	[1406, 1, 283, 236, 0, -10, -36],
	[249, 872, 261, 197, 0, -36, -80],
	[270, 455, 292, 217, 0, -4, -50],
	[1119, 1, 285, 237, 0, -7, -30],
	[1, 285, 267, 269, 0, -18, -17],
	[552, 683, 287, 214, 0, -9, -41],
	[564, 476, 282, 205, 0, -9, -47],
	[1080, 927, 285, 141, 0, -7, -82],
	[1, 1, 274, 282, 0, -10, -10],
	[1814, 428, 89, 275, 0, -103, -13],
	[1129, 810, 290, 115, 0, -5, -90],
	[569, 1, 270, 259, 0, -28, -10],
	[1419, 239, 239, 262, 0, -56, -20],
	[1660, 255, 242, 171, 0, -31, -67]
];
imFrames[14] = [
	[1, 128, 292, 182, 0, -3, -59],
	[295, 246, 284, 216, 0, -10, -39],
	[585, 213, 285, 184, 0, -9, -66],
	[733, 746, 124, 290, 0, -80, -6],
	[860, 542, 127, 257, 0, -91, -28],
	[485, 1221, 188, 269, 0, -61, -21],
	[859, 801, 133, 287, 0, -84, -11],
	[677, 1038, 170, 223, 0, -71, -49],
	[1, 312, 274, 239, 0, -13, -38],
	[485, 972, 190, 247, 0, -70, -32],
	[1, 1, 295, 125, 0, -2, -89],
	[1, 694, 234, 292, 0, -28, -5],
	[594, 1, 290, 210, 0, -6, -39],
	[266, 632, 248, 235, 0, -30, -34],
	[295, 140, 288, 104, 0, -6, -103],
	[516, 746, 215, 224, 0, -43, -36],
	[553, 591, 261, 153, 0, -30, -82],
	[1, 1280, 184, 184, 0, -59, -59],
	[298, 1, 294, 137, 0, -3, -70],
	[277, 464, 274, 166, 0, -13, -62],
	[581, 399, 277, 190, 0, -11, -85],
	[237, 869, 199, 271, 0, -52, -19],
	[1, 988, 192, 290, 0, -56, -8],
	[886, 1, 105, 285, 0, -96, -8],
	[675, 1263, 189, 214, 0, -98, -52],
	[384, 1142, 99, 281, 0, -99, -10],
	[195, 1142, 187, 289, 0, -47, -7],
	[1, 553, 263, 139, 0, -19, -76],
	[872, 288, 119, 252, 0, -88, -19]
];
imFrames[13] = [
	[874, 226, 293, 162, 0, -5, -78],
	[1, 1, 282, 255, 0, -12, -28],
	[1, 532, 243, 286, 0, -30, -10],
	[285, 238, 295, 77, 0, -3, -117],
	[872, 868, 223, 84, 0, -38, -109],
	[1149, 479, 201, 251, 0, -55, -24],
	[1040, 508, 107, 280, 0, -96, -12],
	[1161, 1, 287, 209, 0, -6, -52],
	[416, 521, 158, 289, 0, -76, -7],
	[1352, 479, 87, 273, 0, -106, -18],
	[1278, 754, 213, 236, 0, -43, -39],
	[246, 532, 168, 286, 0, -77, -10],
	[1450, 1, 93, 277, 0, -111, -6],
	[840, 390, 298, 116, 0, 0, -94],
	[263, 317, 291, 202, 0, -5, -55],
	[556, 367, 282, 134, 0, -10, -86],
	[581, 1, 293, 219, 0, -3, -39],
	[1149, 732, 127, 286, 0, -88, -8],
	[1, 820, 283, 197, 0, -8, -59],
	[285, 1, 294, 235, 0, -3, -46],
	[1, 258, 260, 272, 0, -23, -17],
	[799, 508, 239, 266, 0, -33, -17],
	[1441, 280, 107, 266, 0, -102, -20],
	[864, 790, 274, 76, 0, -13, -121],
	[1169, 212, 256, 265, 0, -26, -19],
	[579, 896, 291, 73, 0, -5, -113],
	[576, 503, 221, 288, 0, -51, -9],
	[579, 793, 283, 101, 0, -10, -99],
	[582, 222, 290, 143, 0, -7, -82],
	[286, 820, 291, 186, 0, -3, -62],
	[876, 1, 283, 223, 0, -8, -47]
];
imFrames[15] = [
	[475, 413, 284, 219, 0, -8, -61],
	[761, 447, 249, 228, 0, -38, -37],
	[614, 934, 154, 275, 0, -80, -18],
	[719, 1, 263, 190, 0, -22, -67],
	[1, 114, 275, 211, 0, -15, -44],
	[131, 609, 281, 221, 0, -11, -45],
	[394, 888, 218, 270, 0, -46, -19],
	[1, 832, 282, 269, 0, -10, -16],
	[682, 634, 74, 282, 0, -113, -12],
	[1, 556, 128, 270, 0, -85, -18],
	[563, 193, 281, 218, 0, -9, -40],
	[846, 193, 153, 252, 0, -78, -33],
	[285, 832, 107, 284, 0, -101, -9],
	[583, 1, 134, 169, 0, -85, -77],
	[1, 1103, 208, 278, 0, -45, -18],
	[211, 1118, 144, 285, 0, -81, -13],
	[278, 170, 283, 217, 0, -10, -42],
	[770, 934, 191, 275, 0, -45, -15],
	[1, 1, 288, 111, 0, -7, -88],
	[357, 1160, 198, 281, 0, -55, -12],
	[758, 677, 263, 255, 0, -18, -31],
	[557, 1211, 245, 282, 0, -24, -10],
	[414, 634, 266, 252, 0, -13, -26],
	[1, 327, 184, 227, 0, -58, -35],
	[187, 389, 286, 218, 0, -11, -46],
	[291, 1, 290, 167, 0, -4, -73],
	[804, 1211, 168, 291, 0, -63, -6]
];
imFrames[16] = [
	[655, 226, 164, 288, 0, -69, -7],
	[214, 275, 227, 252, 0, -39, -23],
	[1039, 225, 220, 246, 0, -49, -30],
	[539, 1, 272, 223, 0, -19, -68],
	[821, 219, 216, 253, 0, -38, -25],
	[1070, 1, 239, 222, 0, -42, -36],
	[1311, 1, 175, 270, 0, -63, -15],
	[1309, 273, 122, 278, 0, -89, -13],
	[813, 1, 255, 216, 0, -25, -49],
	[443, 275, 210, 249, 0, -44, -28],
	[1, 1, 253, 262, 0, -19, -22],
	[1, 540, 259, 160, 0, -24, -69],
	[262, 529, 219, 256, 0, -41, -21],
	[697, 516, 148, 269, 0, -75, -18],
	[1261, 225, 46, 217, 0, -114, -54],
	[1149, 473, 150, 284, 0, -63, -11],
	[847, 474, 174, 285, 0, -66, -11],
	[1, 265, 211, 273, 0, -20, -12],
	[1023, 474, 124, 293, 0, -87, -4],
	[483, 526, 212, 257, 0, -48, -28],
	[256, 1, 281, 218, 0, -9, -37],
	[1433, 273, 71, 277, 0, -117, -12],
	[256, 221, 274, 52, 0, -10, -128]
];
imFrames[18] = [
	[564, 619, 275, 282, 0, -15, -9],
	[564, 404, 276, 213, 0, -2, -24],
	[549, 903, 263, 193, 0, -34, -56],
	[585, 190, 281, 212, 0, -11, -42],
	[1, 193, 281, 291, 0, -7, -6],
	[1, 1500, 231, 292, 0, -61, -4],
	[549, 1098, 252, 184, 0, -5, -69],
	[520, 1284, 239, 275, 0, -30, -14],
	[842, 404, 165, 283, 0, -69, -10],
	[803, 1193, 214, 239, 0, -79, -35],
	[270, 1144, 248, 286, 0, -26, -8],
	[1, 995, 267, 258, 0, -19, -23],
	[1, 1255, 248, 243, 0, -41, -30],
	[841, 689, 180, 235, 0, -74, -41],
	[284, 378, 278, 242, 0, -3, -51],
	[444, 1561, 237, 231, 0, -38, -41],
	[280, 622, 273, 279, 0, -18, -13],
	[1, 486, 277, 260, 0, -4, -5],
	[761, 1434, 235, 239, 0, -39, -46],
	[276, 903, 271, 239, 0, -27, -34],
	[300, 184, 283, 192, 0, -5, -53],
	[873, 1, 127, 293, 0, -74, -4],
	[300, 1, 285, 181, 0, -9, -60],
	[1, 748, 273, 245, 0, -14, -31],
	[814, 926, 203, 265, 0, -45, -20],
	[587, 1, 284, 187, 0, -11, -69],
	[234, 1500, 208, 276, 0, -48, -12],
	[1, 1, 297, 190, 0, -1, -59]
];
imFrames[17] = [
	[1474, 160, 154, 277, 0, -81, -13],
	[806, 741, 220, 273, 0, -42, -16],
	[1630, 160, 105, 274, 0, -99, -18],
	[293, 807, 285, 215, 0, -12, -44],
	[253, 548, 284, 257, 0, -6, -30],
	[1462, 722, 156, 264, 0, -70, -20],
	[1462, 440, 171, 280, 0, -87, -11],
	[1, 842, 290, 174, 0, -9, -67],
	[1620, 722, 139, 290, 0, -89, -5],
	[1113, 1, 283, 184, 0, -4, -55],
	[784, 227, 242, 286, 0, -36, -9],
	[1028, 703, 213, 270, 0, -41, -19],
	[1028, 201, 214, 286, 0, -64, -6],
	[824, 1, 287, 198, 0, -7, -56],
	[1243, 692, 217, 236, 0, -42, -29],
	[539, 542, 292, 197, 0, -6, -50],
	[1120, 489, 237, 201, 0, -2, -58],
	[1, 1, 278, 268, 0, -13, -22],
	[530, 261, 252, 279, 0, -42, -7],
	[281, 1, 253, 258, 0, -44, -24],
	[1398, 1, 290, 157, 0, -7, -77],
	[580, 741, 224, 269, 0, -71, -17],
	[1244, 187, 228, 251, 0, -38, -24],
	[271, 271, 257, 262, 0, -37, -23],
	[1635, 436, 115, 284, 0, -95, -15],
	[536, 1, 286, 224, 0, -10, -35],
	[1, 548, 250, 292, 0, -8, -4],
	[1, 271, 268, 275, 0, 0, -7],
	[833, 515, 285, 186, 0, -2, -48]
];
imFrames[19] = [
	[757, 1377, 193, 109, 0, -46, -105],
	[290, 613, 284, 165, 0, -10, -69],
	[1, 1377, 221, 270, 0, -50, -21],
	[1, 1082, 233, 293, 0, -44, -5],
	[291, 421, 287, 190, 0, -4, -45],
	[580, 548, 287, 141, 0, -9, -83],
	[596, 1, 293, 120, 0, -3, -92],
	[286, 780, 278, 278, 0, -13, -15],
	[891, 1, 121, 271, 0, -90, -16],
	[534, 1250, 221, 288, 0, -43, -5],
	[860, 854, 162, 233, 0, -71, -38],
	[321, 1267, 199, 290, 0, -64, -8],
	[576, 691, 282, 188, 0, -9, -64],
	[1, 821, 277, 259, 0, -6, -17],
	[869, 566, 145, 286, 0, -79, -7],
	[280, 1060, 252, 205, 0, -18, -68],
	[1, 453, 287, 187, 0, -6, -63],
	[1, 247, 288, 204, 0, -5, -49],
	[833, 1089, 170, 286, 0, -71, -9],
	[297, 110, 293, 116, 0, -2, -88],
	[592, 123, 289, 236, 0, -5, -39],
	[1, 107, 294, 138, 0, -2, -87],
	[883, 274, 107, 290, 0, -94, -7],
	[566, 985, 265, 263, 0, -16, -18],
	[299, 1, 295, 107, 0, -2, -98],
	[566, 881, 277, 102, 0, -12, -100],
	[236, 1267, 83, 294, 0, -108, -3],
	[1, 1, 296, 104, 0, -2, -105],
	[1, 642, 283, 177, 0, -10, -61],
	[588, 361, 288, 185, 0, -7, -64],
	[297, 228, 289, 191, 0, -5, -62]
];
imFrames[21] = [
	[746, 1, 276, 267, 0, -4, -19],
	[375, 1, 237, 279, 0, -26, -21],
	[203, 1, 170, 293, 0, -74, -4],
	[1154, 392, 294, 90, 0, -4, -86],
	[583, 282, 270, 216, 0, -15, -47],
	[1259, 1, 281, 260, 0, -10, -31],
	[855, 270, 297, 232, 0, -2, -45],
	[614, 1, 130, 274, 0, -79, -13],
	[1, 297, 285, 185, 0, -6, -63],
	[1024, 1, 233, 262, 0, -40, -21],
	[1, 1, 200, 294, 0, -86, -5],
	[1154, 265, 287, 125, 0, -6, -91],
	[288, 296, 293, 146, 0, -4, -91]
];
imFrames[20] = [
	[338, 1202, 150, 174, 0, -77, -75],
	[1, 1522, 125, 239, 0, -90, -44],
	[1, 584, 163, 237, 0, -83, -34],
	[1, 1297, 160, 223, 0, -77, -64],
	[324, 1378, 160, 169, 0, -73, -71],
	[260, 394, 151, 216, 0, -80, -42],
	[1, 339, 166, 243, 0, -72, -45],
	[128, 1522, 62, 237, 0, -120, -32],
	[166, 623, 140, 201, 0, -81, -70],
	[319, 1549, 165, 164, 0, -67, -78],
	[340, 1003, 141, 174, 0, -78, -75],
	[1, 823, 161, 237, 0, -69, -28],
	[1, 1, 253, 170, 0, -22, -66],
	[192, 1544, 125, 169, 0, -98, -67],
	[1, 173, 257, 164, 0, -25, -81],
	[260, 173, 151, 219, 0, -67, -26],
	[413, 173, 67, 234, 0, -117, -32],
	[256, 1, 164, 170, 0, -70, -69],
	[192, 1368, 130, 174, 0, -89, -67],
	[165, 1202, 171, 164, 0, -62, -76],
	[326, 826, 158, 175, 0, -68, -73],
	[169, 339, 89, 282, 0, -101, -11],
	[165, 1022, 173, 178, 0, -66, -64],
	[308, 612, 119, 212, 0, -92, -46],
	[1, 1062, 162, 233, 0, -76, -42],
	[164, 826, 160, 194, 0, -69, -57]
];
imFrames[22] = [
	[1, 299, 281, 284, 0, -9, -8],
	[286, 264, 292, 182, 0, -5, -63],
	[276, 820, 288, 114, 0, -6, -94],
	[1, 1, 283, 296, 0, -9, -2],
	[294, 1279, 231, 230, 0, -34, -35],
	[527, 1279, 111, 243, 0, -88, -40],
	[1, 864, 261, 268, 0, -19, -19],
	[565, 658, 289, 149, 0, -5, -84],
	[286, 1, 291, 261, 0, -6, -30],
	[284, 448, 293, 192, 0, -4, -54],
	[1, 585, 273, 277, 0, -17, -16],
	[264, 936, 286, 143, 0, -7, -69],
	[580, 240, 296, 245, 0, -3, -24],
	[264, 1081, 294, 95, 0, -4, -112],
	[579, 140, 298, 98, 0, -1, -101],
	[566, 919, 207, 276, 0, -50, -16],
	[566, 809, 292, 108, 0, -3, -96],
	[579, 1, 298, 137, 0, -1, -87],
	[1, 1386, 291, 141, 0, -3, -88],
	[579, 487, 288, 169, 0, -7, -64],
	[276, 642, 287, 176, 0, -7, -65],
	[263, 1178, 296, 99, 0, -2, -95],
	[640, 1197, 200, 296, 0, -59, -3],
	[1, 1134, 260, 250, 0, -21, -26]
];
imFrames[24] = [
	[264, 947, 284, 229, 0, -9, -40],
	[552, 157, 288, 168, 0, -7, -63],
	[755, 548, 213, 256, 0, -43, -36],
	[836, 1342, 158, 299, 0, -67, -1],
	[578, 504, 175, 245, 0, -66, -31],
	[837, 1056, 163, 284, 0, -63, -9],
	[1, 1, 275, 120, 0, -9, -97],
	[1, 294, 279, 174, 0, -11, -71],
	[576, 751, 172, 270, 0, -73, -25],
	[550, 1056, 285, 234, 0, -7, -49],
	[278, 1, 285, 129, 0, -11, -87],
	[291, 132, 259, 176, 0, -21, -60],
	[1, 132, 288, 160, 0, -5, -76],
	[285, 722, 289, 223, 0, -4, -41],
	[282, 310, 190, 191, 0, -55, -58],
	[474, 327, 295, 175, 0, -2, -60],
	[293, 504, 283, 216, 0, -8, -46],
	[537, 1292, 297, 246, 0, -3, -37],
	[1, 1178, 291, 245, 0, -4, -38],
	[1, 919, 261, 245, 0, -7, -39],
	[842, 259, 150, 287, 0, -74, -6],
	[565, 1, 286, 154, 0, -10, -87],
	[1, 1425, 269, 261, 0, -28, -28],
	[1, 503, 290, 194, 0, -5, -52],
	[750, 806, 254, 248, 0, -20, -33],
	[1, 699, 282, 218, 0, -10, -52],
	[294, 1178, 241, 261, 0, -33, -15],
	[272, 1441, 261, 265, 0, -18, -27],
	[853, 1, 65, 256, 0, -128, -37]
];
imFrames[25] = [
	[597, 212, 294, 238, 0, -3, -35],
	[300, 1, 296, 196, 0, -3, -47],
	[1, 198, 296, 143, 0, -2, -79],
	[299, 327, 296, 102, 0, -2, -107],
	[294, 814, 290, 185, 0, -4, -50],
	[594, 452, 294, 174, 0, -3, -61],
	[297, 629, 292, 183, 0, -3, -56],
	[298, 431, 294, 196, 0, -4, -50],
	[1, 794, 291, 184, 0, -5, -64],
	[594, 628, 294, 141, 0, -3, -81],
	[293, 1001, 287, 199, 0, -6, -47],
	[598, 1, 292, 209, 0, -4, -42],
	[299, 199, 296, 126, 0, -3, -83],
	[1, 1, 297, 195, 0, -2, -45],
	[284, 1202, 267, 251, 0, -16, -24],
	[586, 915, 287, 209, 0, -6, -52],
	[1, 598, 294, 194, 0, -4, -37],
	[1, 343, 295, 253, 0, -3, -28],
	[1, 1166, 281, 233, 0, -10, -36],
	[582, 1126, 230, 293, 0, -36, -4],
	[1, 980, 290, 184, 0, -4, -50],
	[591, 771, 291, 142, 0, -5, -93]
];
imFrames[23] = [
	[886, 1, 118, 245, 0, -78, -32],
	[693, 896, 159, 238, 0, -69, -35],
	[795, 493, 216, 197, 0, -40, -53],
	[296, 1009, 243, 200, 0, -26, -56],
	[1, 427, 284, 207, 0, -12, -59],
	[278, 1211, 176, 234, 0, -67, -40],
	[762, 1136, 146, 236, 0, -84, -40],
	[295, 387, 262, 190, 0, -18, -53],
	[296, 797, 243, 210, 0, -34, -53],
	[1, 838, 293, 194, 0, -4, -61],
	[541, 850, 150, 255, 0, -76, -30],
	[603, 1136, 157, 235, 0, -72, -35],
	[559, 404, 234, 207, 0, -34, -50],
	[1, 1234, 254, 211, 0, -25, -46],
	[1, 636, 281, 200, 0, -14, -61],
	[836, 248, 172, 243, 0, -57, -32],
	[456, 1211, 145, 229, 0, -88, -41],
	[591, 202, 243, 200, 0, -30, -52],
	[287, 579, 247, 216, 0, -24, -42],
	[591, 1, 293, 199, 0, -4, -51],
	[854, 896, 137, 237, 0, -84, -36],
	[1, 1034, 275, 198, 0, -13, -54],
	[738, 692, 225, 202, 0, -37, -53],
	[297, 208, 280, 177, 0, -11, -68],
	[1, 216, 292, 209, 0, -4, -51],
	[297, 1, 292, 205, 0, -5, -48],
	[1, 1, 294, 213, 0, -5, -44],
	[541, 613, 195, 235, 0, -55, -36]
];
imFrames[26] = [
	[1, 270, 282, 269, 0, -7, -19],
	[862, 1, 288, 240, 0, -5, -29],
	[1730, 276, 269, 279, 0, -17, -15],
	[1396, 788, 195, 264, 0, -49, -24],
	[1593, 788, 155, 293, 0, -72, -4],
	[1750, 764, 140, 288, 0, -88, -7],
	[581, 535, 227, 278, 0, -32, -17],
	[862, 243, 290, 191, 0, -5, -62],
	[290, 261, 293, 188, 0, -5, -56],
	[1723, 557, 273, 205, 0, -24, -55],
	[754, 848, 291, 201, 0, -3, -52],
	[810, 642, 293, 204, 0, -4, -65],
	[1, 541, 265, 285, 0, -27, -4],
	[268, 648, 292, 166, 0, -5, -61],
	[1440, 439, 281, 153, 0, -9, -73],
	[856, 436, 296, 204, 0, -3, -54],
	[582, 1, 278, 255, 0, -15, -30],
	[562, 815, 190, 274, 0, -71, -16],
	[585, 258, 269, 275, 0, -14, -22],
	[1730, 1, 270, 273, 0, -5, -25],
	[285, 451, 294, 195, 0, -2, -57],
	[1, 1, 287, 267, 0, -7, -25],
	[1441, 233, 287, 204, 0, -6, -49],
	[1154, 468, 265, 272, 0, -15, -14],
	[290, 1, 290, 258, 0, -5, -34],
	[1441, 1, 287, 230, 0, -9, -32],
	[282, 816, 257, 268, 0, -25, -23],
	[1152, 1, 287, 232, 0, -9, -34],
	[1421, 594, 297, 192, 0, -2, -59],
	[1154, 235, 284, 231, 0, -11, -43],
	[1, 828, 279, 241, 0, -19, -37],
	[1105, 742, 289, 169, 0, -6, -66]
];
imFrames[27] = [
	[1, 1308, 294, 177, 0, -3, -63],
	[556, 1, 258, 256, 0, -18, -25],
	[736, 1302, 267, 143, 0, -20, -83],
	[686, 810, 279, 187, 0, -10, -65],
	[297, 1308, 287, 160, 0, -9, -67],
	[252, 708, 248, 239, 0, -31, -36],
	[266, 267, 279, 229, 0, -9, -40],
	[542, 542, 222, 263, 0, -35, -19],
	[1, 1, 294, 232, 0, -1, -34],
	[1, 235, 263, 253, 0, -18, -23],
	[736, 999, 270, 158, 0, -20, -78],
	[766, 553, 215, 255, 0, -43, -24],
	[816, 1, 205, 283, 0, -59, -9],
	[782, 286, 229, 265, 0, -42, -16],
	[240, 949, 226, 207, 0, -39, -54],
	[1, 1158, 294, 125, 0, -2, -105],
	[556, 259, 224, 281, 0, -50, -16],
	[297, 1, 257, 264, 0, -26, -25],
	[1, 490, 249, 245, 0, -21, -33],
	[468, 1105, 266, 201, 0, -20, -47],
	[502, 807, 182, 296, 0, -68, -3],
	[252, 498, 288, 208, 0, -7, -47],
	[736, 1159, 275, 141, 0, -12, -86],
	[1, 737, 237, 238, 0, -31, -33]
];
imFrames[28] = [
	[539, 262, 287, 190, 0, -7, -55],
	[288, 260, 249, 293, 0, -12, -5],
	[583, 828, 289, 175, 0, -8, -61],
	[582, 1, 286, 259, 0, -8, -16],
	[556, 454, 285, 180, 0, -9, -69],
	[1, 780, 285, 206, 0, -8, -50],
	[288, 764, 293, 178, 0, -4, -62],
	[281, 555, 273, 207, 0, -9, -50],
	[288, 1, 292, 257, 0, -3, -19],
	[1, 274, 271, 257, 0, -13, -28],
	[1, 1, 285, 271, 0, -8, -20],
	[1, 533, 278, 245, 0, -4, -22],
	[583, 636, 268, 190, 0, -30, -50]
];
imFrames[29] = [
	[679, 1, 43, 277, 0, -130, -15],
	[1586, 362, 280, 213, 0, -17, -57],
	[1339, 498, 228, 202, 0, -33, -56],
	[260, 587, 159, 284, 0, -71, -8],
	[470, 290, 223, 279, 0, -38, -13],
	[181, 1, 220, 293, 0, -45, -4],
	[1579, 577, 275, 211, 0, -13, -48],
	[1071, 516, 163, 247, 0, -70, -25],
	[1301, 760, 276, 140, 0, -12, -80],
	[617, 1, 60, 279, 0, -121, -13],
	[1, 1, 178, 294, 0, -75, -4],
	[659, 571, 203, 275, 0, -46, -8],
	[250, 296, 218, 289, 0, -43, -6],
	[1001, 261, 288, 253, 0, -7, -37],
	[1236, 516, 101, 242, 0, -102, -50],
	[864, 547, 205, 255, 0, -48, -28],
	[852, 274, 147, 271, 0, -81, -17],
	[695, 280, 155, 272, 0, -66, -17],
	[421, 587, 236, 283, 0, -35, -11],
	[724, 1, 191, 271, 0, -84, -23],
	[1071, 765, 228, 131, 0, -34, -73],
	[1291, 256, 293, 240, 0, -2, -38],
	[1, 297, 247, 293, 0, -26, -5],
	[1590, 150, 286, 210, 0, -8, -45],
	[1590, 1, 288, 147, 0, -7, -88],
	[1073, 1, 252, 253, 0, -23, -25],
	[1, 592, 92, 293, 0, -113, -3],
	[1327, 1, 261, 216, 0, -18, -69],
	[917, 1, 154, 258, 0, -77, -15],
	[403, 1, 212, 287, 0, -57, -6],
	[659, 848, 286, 55, 0, -7, -131],
	[95, 592, 163, 290, 0, -71, -8]
];
imFrames[30] = [
	[278, 1, 292, 244, 0, -3, -28],
	[297, 1090, 290, 116, 0, -5, -93],
	[595, 741, 294, 175, 0, -3, -68],
	[571, 271, 210, 294, 0, -41, -4],
	[1, 270, 209, 268, 0, -35, -28],
	[1, 868, 295, 176, 0, -2, -66],
	[1, 680, 296, 186, 0, -2, -58],
	[278, 247, 291, 216, 0, -4, -46],
	[510, 567, 268, 172, 0, -17, -78],
	[1, 1, 275, 267, 0, -15, -21],
	[298, 928, 294, 160, 0, -3, -59],
	[1, 1046, 294, 138, 0, -3, -85],
	[299, 741, 294, 185, 0, -4, -54],
	[783, 291, 201, 289, 0, -41, -8],
	[572, 1, 265, 268, 0, -17, -17],
	[212, 465, 296, 213, 0, -2, -44],
	[594, 1089, 294, 119, 0, -3, -91],
	[595, 918, 292, 169, 0, -5, -69],
	[839, 1, 165, 288, 0, -53, -6],
	[1, 1208, 295, 75, 0, -3, -113]
];

export default imFrames;
