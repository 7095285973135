import EventEmitter from "eventemitter3";
import { Howl, Howler } from 'howler';
import imFrames from "./imframes";
import { audFrames } from './audiovars';

class ContentLoader extends EventEmitter {
	constructor() {
		super();
		this.checkAudioContext = true;
	}

	loadSet(setInd) {
		this.retryCount = 3;
		this.myImage = null;
		this.mySound = null;

		this.tryLoad(setInd);
		this.loadAud(setInd);
		if (this.checkAudioContext) this.checkSuspended();
	}

	loadAud(setInd) {
		let dur = 0;
		let frms = audFrames[setInd];
		let mySprite = [];
		for (let i = 0; i < frms.length; i++) {
			mySprite.push([dur, frms[i]]);
			dur += frms[i];
		}
		if (this.mySound !== null) {
			this.mySound.unload();
			this.mySound = null;
		}
		this.mySound = new Howl({
			src: ['res/contentAud/s_' + setInd + '.ogg', 'res/contentAud/s_' + setInd + '.mp3'],
			sprite: mySprite,
			onload: () => {
				this.emit("audioloaded", this.mySound);
			},
			onloaderror: () => {
				this.mySound.unload();
				this.retryCount -= 1;
				if (this.retryCount < 1) {
					this.emit("audioloaded", null);
				} else {
					this.loadAud(setInd);
				}
			}
		});
	}

	tryLoad(setInd) {
		if (this.myImage !== null) {
			this.myImage.src = "";
			this.myImage = null;
		}
		this.myImage = new Image();
		this.myImage.onload = () => {
			let mySheet = {
				image: this.myImage,
				animation: "0",
				animations: imFrames[setInd]
			};
			this.emit("loadcomplete", mySheet);
		};
		this.myImage.onerror = () => {
			this.myImage.onload = null;
			this.retryCount -= 1;
			if (this.retryCount < 1) {
				this.emit("loadcomplete", null);
			} else {
				this.tryLoad(setInd);
			}
		};
		this.myImage.src = "res/contentIms/im_" + setInd + ".png";
	}

	checkSuspended() {
		this.checkAudioContext = false;
		if ('ctx' in Howler && Howler.ctx !== null) {
			Howler.ctx.resume();
		}
	}
}

export default ContentLoader;
