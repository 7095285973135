const audFrames = [];

audFrames[0] = [1000, 1000, 1000, 1000, 1000, 1000, 1000, 1500, 1500, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1500, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000];
audFrames[1] = [1000, 1000, 1000, 1500, 1500, 1000, 1000, 1000, 1000, 1000, 1000, 1500, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000];
audFrames[2] = [1000, 1500, 1000, 1000, 1500, 1000, 1000, 1500, 1500, 1500, 1000, 1000, 1500, 1000, 1500, 1000, 1000, 1000, 1000, 1500, 1000, 1500];
audFrames[3] = [1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1500, 1000, 1000, 1000, 1500, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1500, 1000, 1000, 1000];
audFrames[4] = [2000, 1000, 1500, 1000, 1000, 1000, 2000, 1500, 1500, 1000, 1000, 1000, 1000, 1500, 1500, 1000, 1000, 1000, 1000, 1500, 1000, 1000, 1500, 1500, 1000, 1000, 1500];
audFrames[5] = [1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1500, 1000, 1000, 1500, 1500, 1500, 1000, 1500, 1000, 1000, 1000];
audFrames[6] = [1000, 1000, 1000, 1000, 1000, 1500, 1500, 1000, 1500, 1000, 1000, 1500, 1500, 1500, 1500, 1500, 1000, 1500, 1000, 1000, 1000, 1500, 1500, 1000, 2000, 1500, 1500, 1500];
audFrames[7] = [1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000];
audFrames[8] = [1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1500, 1500, 1000, 1500, 1500, 1500, 1000, 1000, 1000, 1000, 1000, 2000, 1500];
audFrames[9] = [1000, 1500, 1000, 1000, 1500, 1500, 1000, 1000, 1500, 1000, 1000, 1000, 1000, 1000, 1500, 1000, 1000, 1000, 1000, 1000];
audFrames[10] = [1000, 1500, 1000, 1500, 1000, 1000, 1000, 1500, 1500, 1000, 1500, 1000, 1000, 1000, 1000, 1500, 1000, 1000, 1000, 1500, 1000, 1000, 1000, 1000, 1000, 1000, 1500, 1000, 1000, 1000, 1500, 1000, 1000, 1000, 1000];
audFrames[11] = [1500, 1500, 1500, 1500, 1000, 1000, 1500, 1000, 1000, 1000, 1000, 1000, 1500, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1500, 1000, 1500, 1500, 1000, 1500, 1000, 1500, 1000, 1000, 1500, 1000, 1500, 1000];
audFrames[12] = [1500, 1500, 2000, 1000, 1000, 1500, 1000, 1000, 2000, 2000, 1500, 1000, 1000, 1500, 1000, 1000, 1000, 1500, 1000, 1500, 1500, 1000, 1000, 1000, 1500, 1000, 1500, 1000, 1500, 2000, 1000, 1000, 1000, 1000, 2000];
audFrames[13] = [1000, 1000, 1500, 1000, 1000, 1500, 1500, 1500, 1000, 1000, 1000, 1500, 1000, 1500, 2000, 1000, 1000, 1000, 1500, 1000, 1500, 1000, 1000, 1000, 1000, 1000, 1500, 1000, 1000, 1000, 1000];
audFrames[14] = [1000, 1000, 1500, 1000, 1000, 1000, 1000, 1000, 1500, 1500, 1000, 1000, 1500, 1000, 1000, 1000, 1500, 1000, 1000, 1000, 1000, 1000, 1000, 1500, 1500, 1000, 1500, 1000, 1000];
audFrames[15] = [1500, 1000, 1000, 1500, 1500, 1500, 1000, 1500, 1500, 2000, 1000, 1000, 1500, 1500, 1500, 1500, 1500, 1500, 1000, 1500, 1000, 1000, 2000, 1500, 1500, 1000, 1000];
audFrames[16] = [1500, 1000, 1500, 1000, 1500, 1000, 1000, 1500, 1500, 1000, 1500, 1500, 1500, 1500, 1500, 1500, 1500, 1000, 1000, 1500, 1500, 1000, 1000];
audFrames[17] = [1500, 1500, 1500, 1500, 1000, 1000, 1000, 1500, 1000, 1000, 1500, 1500, 1000, 1000, 1000, 1500, 1000, 1000, 1500, 1500, 1000, 1000, 1000, 1000, 1000, 1000, 1500, 1500, 1500];
audFrames[18] = [1000, 1000, 1000, 1000, 1000, 1000, 1500, 1000, 1500, 1000, 1500, 1000, 2000, 1000, 1000, 1000, 1500, 1000, 1500, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000];
audFrames[19] = [1000, 1000, 1000, 1500, 1000, 1000, 1500, 1000, 1500, 1000, 1000, 1000, 1500, 1500, 1000, 1000, 1500, 1000, 2000, 1000, 1000, 1500, 1500, 1500, 1500, 1000, 1000, 1000, 1000, 1000, 1000];
audFrames[20] = [1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000];
audFrames[21] = [1000, 1500, 1500, 1000, 1500, 1000, 1000, 1000, 1000, 1000, 1000, 1500, 1000];
audFrames[22] = [1500, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1500, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1500, 1000, 1000, 1000];
audFrames[23] = [1000, 1000, 1000, 1500, 1000, 1000, 1000, 1500, 1500, 1000, 1000, 1000, 1500, 1000, 1000, 1000, 1000, 1500, 1500, 1000, 1000, 1000, 1500, 1000, 1000, 1000, 1000, 1000];
audFrames[24] = [1500, 1000, 1500, 1500, 1000, 1000, 1000, 1000, 1500, 1500, 1500, 1500, 1500, 1500, 1000, 1500, 1000, 1000, 1500, 1000, 1000, 1500, 1000, 1000, 1000, 1000, 1500, 1500, 1000];
audFrames[25] = [1000, 1000, 1000, 1000, 1000, 1000, 1500, 1000, 1500, 1000, 1000, 1000, 1500, 1500, 1000, 1000, 1000, 1500, 1000, 1500, 1000, 1000, 1500];
audFrames[26] = [1000, 1000, 1500, 1000, 1000, 1000, 1000, 2000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1500, 1000, 1000, 1000, 1000, 1000, 1500, 1000, 1500, 1000, 1000, 1000, 1500, 1000, 1500];
audFrames[27] = [1000, 1000, 1500, 1500, 1000, 1000, 1000, 1500, 1000, 1500, 1500, 1000, 1000, 1500, 1000, 1000, 1000, 1000, 1000, 1500, 1500, 1500, 1000, 1000];
audFrames[28] = [1500, 1500, 1000, 1500, 1000, 1000, 1500, 1000, 1500, 1000, 1000, 1000, 1000];
audFrames[29] = [1000, 1000, 1000, 1500, 1000, 1500, 1000, 1500, 1000, 1500, 1500, 1000, 1500, 1500, 1500, 1000, 1000, 1500, 1000, 1500, 1500, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000];
audFrames[30] = [1000, 1000, 1000, 1000, 1000, 1500, 1000, 1500, 1500, 1500, 1000, 1000, 1000, 1000, 1000, 1500, 1000, 1500, 1500, 1000];

export { audFrames };
