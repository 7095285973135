class BordButton extends Konva.Group {
	constructor(w, h, m, r1, r2, s1, f1, s2, f2, f3, s1w, s2w) {
		super();
		this.overCol = f3;
		this.outCol = f2;
		this.width = w;
		if (s1w === undefined)s1w = 1;
		if (s2w === undefined)s2w = 1;
		let back = new Konva.Rect({
			width: w, height: h, fill: f1, stroke: s1, cornerRadius: r1, strokeWidth: s1w
		});
		this.front = new Konva.Rect({
			width: w - m * 2, height: h - m * 2, fill: f2, stroke: s2, cornerRadius: r2, strokeWidth: s2w
		});
		this.front.listening(false);
		this.front.position({ x: m, y: m });
		this.add(back, this.front);
		this.offsetX(w / 2);
		this.offsetY(h / 2);
	}

	addText(txt, fs, font, color, y) {
		let myText = new Konva.Text({
			text: txt, fontSize: fs, fontFamily: font, width: this.width, y, align: 'center', fontStyle: "bold", fill: color
		});
		myText.listening(false);
		this.add(myText);
	}

	showOver() {
		this.front.fill(this.overCol);
	}

	showOut() {
		this.front.fill(this.outCol);
	}
}

export default BordButton;
