//maybe make a components file like shapes
import { tick, cross } from './utils';

class TickCross extends Konva.Group {
	constructor(ind) {
		super();
		this.ind = ind;
		this.tick = tick(44, '#555555', '#80C47D');
		this.cross = cross(8, 16, 4, '#555555', '#F97474');
		let outer = new Konva.Circle({ radius: 46, fill: '#fff', stroke: '#ccc', strokeWidth: 1 });
		let inner = new Konva.Circle({ radius: 40, fill: '#eee', stroke: '#ccc', strokeWidth: 1 });
		this.cross.visible(false);
		this.add(outer, inner, this.tick, this.cross);
	}

	set(state) {
		if (state === true) {
			this.tick.visible(true);
			this.cross.visible(false);
		} else {
			this.tick.visible(false);
			this.cross.visible(true);
		}
	}
}

export default TickCross;
