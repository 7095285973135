const contentLims = [
	33,
	30,
	22,
	33,
	27,
	28,
	28,
	10,
	22,
	20,
	35,
	35,
	35,
	31,
	29,
	27,
	23,
	29,
	28,
	31,
	26,
	13,
	24,
	28,
	29,
	22,
	32,
	24,
	13,
	32,
	20
];

const setTitles = [
	"Animals",
	"Animals  2",
	"Bathroom",
	"Body",
	"Buildings",
	"Clothes",
	"Clothes  2",
	"Colors",
	"Computer",
	"Countries",
	"Food",
	"Food  2",
	"Food  3",
	"Garden",
	"Home",
	"Home  2",
	"Hospital",
	"Jobs",
	"Jobs  2",
	"Kitchen",
	"Letters",
	"Music",
	"Nature",
	"Numbers",
	"Personal",
	"Places",
	"Sports",
	"Stationery",
	"Subjects",
	"Things",
	"Transport"
];

const textSets = [];

textSets[0] = [
	"cat",
	"frog",
	"chicken",
	"turtle",
	"crab",
	"rabbit",
	"shark",
	"crocodile",
	"giraffe",
	"cow",
	"horse",
	"butterfly",
	"bull",
	"pig",
	"rhino",
	"sheep",
	"snake",
	"panda",
	"fish",
	"penguin",
	"tiger",
	"duck",
	"bat",
	"dragon",
	"fox",
	"elephant",
	"spider",
	"fly",
	"mouse",
	"monkey",
	"bird",
	"dog",
	"skunk"
];

textSets[1] = [
	"eagle",
	"swan",
	"bee",
	"tortoise",
	"wolf",
	"lion",
	"owl",
	"caterpillar",
	"gorilla",
	"hippo",
	"goat",
	"snail",
	"beetle",
	"kangaroo",
	"parrot",
	"whale",
	"grasshopper",
	"ant",
	"camel",
	"lizard",
	"rat",
	"zebra",
	"donkey",
	"scorpion",
	"squirrel",
	"bear",
	"dolphin",
	"octopus",
	"deer",
	"peacock"
];

textSets[2] = [
	"soap",
	"toothpaste",
	"shampoo",
	"comb",
	"dental floss",
	"sink",
	"toilet",
	"hairbrush",
	"toilet paper",
	"lipstick",
	"towel",
	"bath",
	"toothbrush",
	"razor",
	"plug hole",
	"shower",
	"faucet",
	"mirror",
	"hair dryer",
	"hand dryer",
	"plug",
	"nail clippers"
];

textSets[3] = [
	"eyes",
	"teeth",
	"toes",
	"head",
	"eyebrow",
	"ears",
	"hair",
	"shoulder",
	"tongue",
	"bones",
	"hand",
	"finger",
	"knee",
	"moustache",
	"ankle",
	"nose",
	"leg",
	"thumb",
	"neck",
	"heel",
	"mouth",
	"beard",
	"elbow",
	"belly",
	"armpit",
	"arm",
	"foot",
	"wrist",
	"chin",
	"belly button",
	"wings",
	"feathers",
	"tail"
];

textSets[4] = [
	"apartment block",
	"bridge",
	"movie theater",
	"garage",
	"pet store",
	"hospital",
	"skyscrapers",
	"gas station",
	"construction site",
	"church",
	"castle",
	"airport",
	"tunnel",
	"convenience store",
	"florist",
	"stadium",
	"factory",
	"windmill",
	"zoo",
	"car park",
	"house",
	"statue",
	"train station",
	"lighthouse",
	"tent",
	"road",
	"billboard"
];

textSets[5] = [
	"Tshirt",
	"suit",
	"belt",
	"pants",
	"vest",
	"gloves",
	"dress",
	"ring",
	"skirt",
	"earring",
	"shirt",
	"jacket",
	"scarf",
	"shorts",
	"tie",
	"coat",
	"socks",
	"earphones",
	"sweater",
	"umbrella",
	"shoes",
	"swimsuit",
	"glasses",
	"hat",
	"bracelet",
	"watch",
	"necklace",
	"bag"
];

textSets[6] = [
	"jeans",
	"blazer",
	"backpack",
	"slippers",
	"apron",
	"sneakers",
	"tracksuit",
	"mittens",
	"flip flops",
	"crown",
	"boots",
	"polo shirt",
	"cargo shorts",
	"sandals",
	"goggles",
	"sunglasses",
	"hoodie",
	"blouse",
	"bow tie",
	"helmet",
	"cap",
	"raincoat",
	"high heels",
	"cardigan",
	"swimming trunks",
	"headphones",
	"handbag",
	"dressing gown"
];

textSets[7] = [
	"red",
	"yellow",
	"black",
	"orange",
	"purple",
	"blue",
	"green",
	"white",
	"grey",
	"brown"
];

textSets[8] = [
	"mouse",
	"microphone",
	"battery",
	"calculator",
	"socket",
	"controller",
	"monitor",
	"printer",
	"line",
	"switch",
	"laptop",
	"keyboard",
	"speakers",
	"disc",
	"light bulb",
	"tablet",
	"phone",
	"plug",
	"headset",
	"camera",
	"usb stick",
	"satellite dish"
];

textSets[9] = [
	"America",
	"Australia",
	"Canada",
	"Ireland",
	"South Africa",
	"France",
	"England",
	"Spain",
	"Thailand",
	"Egypt",
	"China",
	"India",
	"Italy",
	"Turkey",
	"Argentina",
	"Russia",
	"Germany",
	"Japan",
	"Brazil",
	"Mexico"
];

textSets[10] = [
	"chicken",
	"hamburger",
	"chocolate",
	"noodles",
	"baguette",
	"apple",
	"sandwich",
	"peas",
	"mangoes",
	"muffin",
	"oranges",
	"candy",
	"ketchup",
	"shrimp",
	"coconuts",
	"bananas",
	"fish",
	"pie",
	"wine",
	"salt and pepper",
	"eggs",
	"lamb",
	"lettuce",
	"broccoli",
	"lime",
	"milk",
	"peppers",
	"pork",
	"sushi",
	"avocado",
	"tomatoes",
	"beef",
	"coffee",
	"lobster",
	"lemonade"
];

textSets[11] = [
	"cheeseburger",
	"bread rolls",
	"fried egg",
	"spaghetti",
	"celery",
	"watermelon",
	"donuts",
	"burrito",
	"cauliflower",
	"walnut",
	"lemon",
	"corn",
	"lollipop",
	"croissant",
	"eggplant",
	"cake",
	"steak",
	"water",
	"cherries",
	"olives",
	"pizza",
	"cheese",
	"soup",
	"waffles",
	"grapefruit",
	"pears",
	"salad",
	"onions",
	"kebabs",
	"pumpkin",
	"ice cream",
	"fries",
	"tea",
	"orange juice",
	"ginger"
];

textSets[12] = [
	"carrots",
	"rice",
	"boiled eggs",
	"cabbage",
	"garlic",
	"mushrooms",
	"pasta",
	"chilies",
	"hot chocolate",
	"instant noodles",
	"strawberries",
	"cucumber",
	"peanuts",
	"potatoes",
	"pineapple",
	"bread",
	"chips",
	"apple juice",
	"cereal",
	"milkshake",
	"sausages",
	"ice",
	"popcorn",
	"cookie",
	"tangerine",
	"hot dog",
	"grapes",
	"butter",
	"plums",
	"spaghetti bolognese",
	"bacon",
	"cola",
	"peaches",
	"taco",
	"kiwifruit"
];

textSets[13] = [
	"bench",
	"shed",
	"lawn mower",
	"drain",
	"peg",
	"fireworks",
	"spade",
	"wheelbarrow",
	"mop",
	"screw",
	"bucket",
	"flag",
	"broom",
	"clothes line",
	"dustpan and brush",
	"gate",
	"swing",
	"ladder",
	"screwdriver",
	"drill",
	"slide",
	"fountain",
	"hammer",
	"nail",
	"chimney",
	"fence",
	"barbecue",
	"wall",
	"saw",
	"hammock",
	"tape measure"
];

textSets[14] = [
	"bed",
	"computer",
	"air conditioner",
	"mirror",
	"bin",
	"chair",
	"door",
	"picture",
	"fireplace",
	"stove",
	"sofa",
	"cupboard",
	"books",
	"cushion",
	"rug",
	"window",
	"shelves",
	"clock",
	"stereo",
	"map",
	"table",
	"plant",
	"dresser",
	"water dispenser",
	"door handle",
	"light",
	"telescope",
	"coat hanger",
	"coat hook"
];

textSets[15] = [
	"desk",
	"fan",
	"heater",
	"doormat",
	"lockers",
	"drawers",
	"lamp",
	"fridge",
	"coat rack",
	"fire extinguisher",
	"tv",
	"stool",
	"vase",
	"remote",
	"deckchair",
	"bookcase",
	"curtains",
	"vacuum cleaner",
	"handrail",
	"trash can",
	"armchair",
	"closet",
	"washing machine",
	"alarm clock",
	"recliner",
	"pillow",
	"swivel chair"
];

textSets[16] = [
	"broken arm",
	"black eye",
	"bandage",
	"stretcher",
	"stomach ache",
	"medicine",
	"cough",
	"rash",
	"syringe",
	"scar",
	"wheelchair",
	"band aid",
	"nose bleed",
	"stethoscope",
	"walking stick",
	"broken leg",
	"crutches",
	"cut",
	"x ray",
	"sore throat",
	"headache",
	"thermometer",
	"needle"
];

textSets[17] = [
	"policeman",
	"receptionist",
	"nurse",
	"graphic designer",
	"mechanic",
	"doctor",
	"artist",
	"computer technician",
	"dancer",
	"cashier",
	"firefighter",
	"hairdresser",
	"pilot",
	"driver",
	"carpenter",
	"scientist",
	"reporter",
	"musician",
	"window cleaner",
	"lumberjack",
	"teacher",
	"dishwasher",
	"farmer",
	"waiter",
	"mailman",
	"baker",
	"electrician",
	"politician",
	"car salesman"
];

textSets[18] = [
	"cleaner",
	"builder",
	"engineer",
	"plumber",
	"decorator",
	"dentist",
	"fashion designer",
	"vet",
	"security guard",
	"tutor",
	"chef",
	"surgeon",
	"shop assistant",
	"photographer",
	"masseuse",
	"singer",
	"pharmacist",
	"tour guide",
	"florist",
	"gardener",
	"fisherman",
	"soldier",
	"illustrator",
	"barman",
	"clown",
	"truck driver",
	"deliveryman",
	"chauffeur"
];

textSets[19] = [
	"cup",
	"spoon",
	"kettle",
	"coffee machine",
	"corkscrew",
	"bowl",
	"frying pan",
	"toaster",
	"wine glass",
	"mixer",
	"glass",
	"jug",
	"microwave",
	"dishcloth",
	"grater",
	"knife",
	"saucepan",
	"teapot",
	"paper towels",
	"cleaver",
	"fork",
	"chopsticks",
	"tea towel",
	"bottle opener",
	"chopping board",
	"plate",
	"bottle",
	"tray",
	"spatula",
	"peeler",
	"can opener"
];

textSets[20] = [
	"a",
	"f",
	"k",
	"p",
	"u",
	"b",
	"g",
	"l",
	"q",
	"v",
	"c",
	"h",
	"m",
	"r",
	"w",
	"d",
	"i",
	"n",
	"s",
	"x",
	"e",
	"j",
	"o",
	"t",
	"y",
	"z"
];

textSets[21] = [
	"violin",
	"keyboard",
	"saxophone",
	"trumpet",
	"clarinet",
	"piano",
	"drums",
	"guitar",
	"accordion",
	"tambourine",
	"harp",
	"harmonica",
	"banjo"
];

textSets[22] = [
	"the sun",
	"rainbow",
	"river",
	"volcano",
	"the moon",
	"flower",
	"snow",
	"bush",
	"lightning",
	"planet",
	"rain",
	"clouds",
	"mountain",
	"forest",
	"cliffs",
	"tree",
	"leaf",
	"desert",
	"lake",
	"wind",
	"stars",
	"island",
	"tornado",
	"the earth"
];

textSets[23] = [
	"one",
	"six",
	"eleven",
	"sixteen",
	"thirty",
	"two",
	"seven",
	"twelve",
	"seventeen",
	"forty",
	"three",
	"eight",
	"thirteen",
	"eighteen",
	"fifty",
	"four",
	"nine",
	"fourteen",
	"nineteen",
	"sixty",
	"five",
	"ten",
	"fifteen",
	"twenty",
	"seventy",
	"eighty",
	"ninety",
	"zero"
];

textSets[24] = [
	"bag",
	"purse",
	"wallet",
	"suitcase",
	"zip",
	"watch",
	"keys",
	"money",
	"headphones",
	"gift",
	"glasses",
	"ID card",
	"flashlight",
	"newspaper",
	"button",
	"laptop",
	"pen",
	"book",
	"tissues",
	"kite",
	"phone",
	"credit card",
	"mug",
	"whistle",
	"straw",
	"ticket",
	"basket",
	"cards",
	"flower"
];

textSets[25] = [
	"bedroom",
	"park",
	"office",
	"pool",
	"corridor",
	"living room",
	"library",
	"classroom",
	"supermarket",
	"playground",
	"bathroom",
	"store",
	"restaurant",
	"shopping center",
	"study",
	"kitchen",
	"cafe",
	"hair salon",
	"gym",
	"stairs",
	"garden",
	"beach"
];

textSets[26] = [
	"soccer",
	"cycling",
	"weightlifting",
	"wrestling",
	"diving",
	"basketball",
	"baseball",
	"American football",
	"hockey",
	"bowling",
	"swimming",
	"pool",
	"chess",
	"cricket",
	"archery",
	"boxing",
	"volleyball",
	"skateboarding",
	"golf",
	"javelin",
	"tennis",
	"running",
	"skiing",
	"horse riding",
	"high jump",
	"table tennis",
	"karate",
	"badminton",
	"surfing",
	"darts",
	"rugby",
	"long jump"
];

textSets[27] = [
	"pen",
	"ruler",
	"paper clips",
	"stapler",
	"marker",
	"pencil",
	"tape",
	"notepad",
	"clipboard",
	"fountain pen",
	"pencil case",
	"calculator",
	"folder",
	"paintbrush",
	"sharpener",
	"scissors",
	"crayons",
	"blackboard",
	"paper",
	"pins",
	"flip chart",
	"whiteboard",
	"eraser",
	"envelope"
];

textSets[28] = [
	"maths",
	"physical education",
	"music",
	"Spanish",
	"chemistry",
	"English",
	"geography",
	"computing",
	"Chinese",
	"art",
	"history",
	"physics",
	"biology"
];

textSets[29] = [
	"candle",
	"magnet",
	"satchel",
	"traffic cone",
	"atm",
	"backpack",
	"dice",
	"padlock",
	"letter",
	"traffic light",
	"microscope",
	"balloon",
	"briefcase",
	"shopping cart",
	"stop sign",
	"globe",
	"can",
	"rucksack",
	"sword",
	"street light",
	"coins",
	"box",
	"luggage",
	"axe",
	"bolt",
	"ball",
	"lighter",
	"drone",
	"medal",
	"snorkel",
	"chain",
	"shield"
];

textSets[30] = [
	"bike",
	"car",
	"van",
	"scooter",
	"rocket",
	"bus",
	"truck",
	"ambulance",
	"skateboard",
	"moped",
	"plane",
	"train",
	"helicopter",
	"escalator",
	"tractor",
	"motorbike",
	"boat",
	"fire engine",
	"elevator",
	"limo"
];

export { contentLims, setTitles, textSets };
