import EventEmitter from "eventemitter3";

class DragBar extends Konva.Group {
	constructor(ind, invs, dw, dh, dr, bw, bh, br, xBnds) {
		super();
		this.initialSet = false;
		this.ind = ind;
		this.eEmitter = new EventEmitter();
		this.xBound = xBnds;
		this.numIntervals = invs;
		this.intervalWidth = Math.floor(this.xBound / this.numIntervals);
		this.back = new Konva.Rect({
			width: bw, height: bh, offsetX: bw / 2, offsetY: bh / 2, fill: '#ccc', stroke: '#999', cornerRadius: br, strokeWidth: 1
		});
		this.bar = new Konva.Rect({
			width: dw, height: dh, offsetX: dw / 2, offsetY: dh / 2, fill: '#666', stroke: '#444', cornerRadius: dr, strokeWidth: 1
		});
		this.add(this.back, this.bar);
	}

	barDrop(e) {
		let xPos = Math.round(e.currentTarget.position().x + this.xBound / 2);
		let nInt = Math.floor(xPos / this.intervalWidth);
		if (nInt > this.numIntervals - 1)nInt = this.numIntervals - 1;
		if (nInt < 0)nInt = 0;
		this.eEmitter.emit('bardrop', this.ind, nInt);
	}

	setIntial(nInt) {
		if (this.initialSet === false) {
			this.initialSet = true;
			let xPos = Math.round(nInt * this.intervalWidth - this.xBound / 2 + this.intervalWidth / 2);
			this.bar.position({ x: xPos });
			this.myScale = this.getAbsoluteTransform().m[0];
			this.bar.dragBoundFunc((pos) => {
				let newX = pos.x / this.myScale;
				let aPosX = this.absolutePosition().x / this.myScale;
				let xBnd = this.xBound / 2;
				if (newX < aPosX - xBnd)newX = aPosX - xBnd;
				if (newX > aPosX + xBnd)newX = aPosX + xBnd;
				newX *= this.myScale;
				return { x: newX, y: this.absolutePosition().y };
			});
		}
	}

	update(bool) {
		this.bar.visible(bool);
		let opa = bool ? 1 : 0.3;
		this.opacity(opa);
	}

	init() {
		this.bar.draggable(true);
		this.bar.on('dragend', this.barDrop.bind(this));
	}

	deit() {
		this.bar.draggable(false);
		this.bar.off('dragend');
	}
}

export default DragBar;
