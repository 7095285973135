function	borderRect(w, h, m, r1, r2, s1, f1, s2, f2, s1w, s2w) {
	if (s1w === undefined)s1w = 1;
	if (s2w === undefined)s2w = 1;
	let grp = new Konva.Group();
	let back = new Konva.Rect({
		width: w, height: h, fill: f1, stroke: s1, cornerRadius: r1, strokeWidth: s1w
	});
	let front = new Konva.Rect({
		width: w - m * 2, height: h - m * 2, fill: f2, stroke: s2, cornerRadius: r2, strokeWidth: s2w, listening: false
	});
	front.position({ x: m, y: m });
	grp.add(back, front);
	grp.offsetX(w / 2);
	grp.offsetY(h / 2);
	return grp;
}

function tick(len, sCol, fCol) {
	let hei = len;
	let wid = len / 3;
	let rad = len / 10;
	let myTick = new Konva.Shape({
		fill: fCol,
		stroke: sCol,
		strokeWidth: 1,
		listening: false,
		sceneFunc(context, shape) {
			context.beginPath();
			//	context.moveTo(0, 0);
			context.arc(wid / 2, -hei / 2, rad, -Math.PI / 2, 0);
			context.arc(wid / 2, hei / 2, rad, 0, Math.PI / 2);
			context.arc(-wid / 2 - rad, hei / 2, rad, Math.PI / 2, Math.PI);
			context.arc(-wid / 2 - rad, hei / 2 - wid / 2 + rad / 2, rad, Math.PI, -Math.PI / 2);
			context.arc(-rad / 2, hei / 2 - wid / 2 - rad / 2, 0, 0, Math.PI / 2);
			context.arc(rad / 2, -hei / 2, rad, Math.PI, -Math.PI / 2);
			context.closePath();
			// Konva specific method
			context.fillStrokeShape(shape);
		}
	});
	myTick.rotation(35);
	return myTick;
}

function cross(wid, len, rad, sCol, fCol) {
	let myCross = new Konva.Shape({
		fill: fCol,
		stroke: sCol,
		strokeWidth: 1,
		listening: false,
		sceneFunc(context, shape) {
			context.beginPath();
			context.arc(wid / 2, -len - wid / 2, rad, -Math.PI / 2, 0);
			context.arc(wid / 2 + rad, -wid / 2 - rad, 0, -Math.PI / 2, 0);
			context.arc(wid / 2 + len, -wid / 2, rad, -Math.PI / 2, 0);
			context.arc(wid / 2 + len, wid / 2, rad, 0, Math.PI / 2);
			context.arc(wid / 2 + rad, wid / 2 + rad, 0, 0, Math.PI / 2);
			context.arc(wid / 2, len + wid / 2, rad, 0, Math.PI / 2);
			context.arc(-wid / 2, len + wid / 2, rad, Math.PI / 2, Math.PI);
			context.arc(-wid / 2 - rad, wid / 2 + rad, 0, Math.PI / 2, Math.PI);
			context.arc(-wid / 2 - len, wid / 2, rad, Math.PI / 2, Math.PI);
			context.arc(-wid / 2 - len, -wid / 2, rad, Math.PI, -Math.PI / 2);
			context.arc(-wid / 2 - rad, -wid / 2 - rad, 0, Math.PI, -Math.PI / 2);
			context.arc(-wid / 2, -wid / 2 - len, rad, Math.PI, -Math.PI / 2);
			context.closePath();
			context.fillStrokeShape(shape);
		}
	});
	myCross.rotation(45);
	return myCross;
}

function borderCircle(r, m, s1, f1, s2, f2, sw1, sw2) {
	if (sw1 === undefined)sw1 = 1;
	if (sw2 === undefined)sw2 = 1;
	let circle = new Konva.Group();
	let outer = new Konva.Circle({
		radius: r,
		fill: f1,
		stroke: s1,
		strokeWidth: sw1
	});
	let inner = new Konva.Circle({
		radius: r - m,
		fill: f2,
		stroke: s2,
		strokeWidth: sw2,
		listening: false
	});
	circle.add(outer, inner);
	return circle;
}

function circleArrow(r, m, s1, f1, s2, f2, sw1, sw2) {
	let cArrow = borderCircle(r, m, s1, f1, s2, f2, sw1, sw2);
	let size = 14;
	let arr = new Konva.Shape({
		fill: '#555',
		//	stroke: '#000',
		//	strokeWidth: 1,
		listening: false,
		sceneFunc(context, shape) {
			context.beginPath();
			context.arc(0, 0, size * 0.56, -1, 1);
			context.arc(-size, size, size * 0.40, 1, Math.PI);
			context.arc(-size, -size, size * 0.40, Math.PI, -1);
			context.lineTo(size * 0.4, -size * 0.4);
			context.closePath();
			context.fillStrokeShape(shape);
		}
	});
	arr.position({ x: 9 });
	cArrow.add(arr);
	return cArrow;
}

export { borderRect, circleArrow, borderCircle, tick, cross };
