import ContentLoader from "./contentloader";
import Background from "./background";
import VocabController from "./vocabcontroller";
import ContentView from "./contentview";
import { textSets } from "./textcontent";
import Preloader from "./preloader";

const DEF_WIDTH = 800;
const DEF_HEIGHT = 550;

//////////////////////////////
//if opdome ever gets popular - then add long content ims too
//test for contentloader being cleaned up
//fadein views
//preloading screen
//icons should be svg
//mouse overs for arrow and autio button
//slight delay or fade for show loading so doesn't show when in cache already
//mouseovers show list of items in contentview
//hash preset loading
//vertical align to center when on mobile
//restictions on settings - no aud delay longer than auto delay
//test for browser supported - display not supported if cant arrow function
//add ... dots when loading and then loadfailed msg if fails
//make full screen if dimensions are suitable Eg mobile

class Controller {
	init(mainSheet) {
		this.fontsLoaded = this.fontsLoaded.bind(this);
		let w = document.getElementById('containerDiv').clientWidth;
		let h = window.innerHeight;
		let sProps = this.getScreenProps(w, h);
		this.makeStage(sProps);
		//this.stage.on('mousemove', (e) => { console.log(e.evt.x, e.evt.y); });

		this.contentLoader = new ContentLoader();
		this.background = new Background(sProps.orientation, mainSheet);
		//the code for the main vocab screen is split into a controller and view
		this.vocabController = new VocabController(sProps.orientation, mainSheet);
		//the code for the content screen is just in a view
		this.contentView = new ContentView(sProps.orientation, mainSheet);
		this.stage.add(this.background);

		this.setInd = 0;

		this.contentLoader.on("loadcomplete", this.loadComplete.bind(this));
		this.contentLoader.on("audioloaded", this.audioLoaded.bind(this));

		this.showContent();
		let pHash = this.checkPresetHash();
		if (pHash !== null) this.loadSet(pHash);
	}

	fontsLoaded() {
		this.contentView.fontsLoaded();
	}

	/////////////////////////////////////////////////////////////
	//contentView

	showContent() {
		this.contentView.eEmitter.on("showpointer", this.showPointer.bind(this));
		this.contentView.eEmitter.on("loadset", this.loadSet.bind(this));
		this.stage.add(this.contentView);
		this.contentView.init();
	}

	removeContent() {
		this.contentView.eEmitter.off("showpointer");
		this.contentView.eEmitter.off("loadset");
		this.contentView.deit();
		this.contentView.remove();
	}

	/////////////////////////////////////////////////////////////
	//loading
	loadSet(ind) {
		this.setInd = ind;
		this.contentView.showLoading();
		this.contentLoader.loadSet(ind);
	}

	loadComplete(contentSheet) {
		if (contentSheet !== null) {
			this.showVocab(contentSheet);
		} else {
			console.log('load failed');
			this.contentView.init();
		}
	}

	audioLoaded(myAudio) { //unit test
		if (myAudio !== null) {
			//this.myAudio = myAudio;
			this.vocabController.setAudio(myAudio);
		} else {
			//failed
		}
	}

	/////////////////////////////////////////////////////////////
	//vocabView

	showVocab(contentSheet) {
		this.removeContent();
		this.vocabController.eEmitter.on("showpointer", this.showPointer.bind(this));
		this.vocabController.eEmitter.on("exitclick", this.exitVocab.bind(this));
		this.stage.add(this.vocabController.view);
		this.vocabController.init(contentSheet, textSets[this.setInd]);
	}

	exitVocab() {
		this.vocabController.eEmitter.off("showpointer");
		this.vocabController.eEmitter.off("exitclick");
		this.vocabController.view.remove();
		this.vocabController.deit();
		this.showContent();
	}

	/////////////////////////////////////////////////////////////
	//stage

	makeStage(sProps) {
		//what about alt text for canvas?
		this.stage = new Konva.Stage({
			container: "containerDiv",
			width: sProps.wid,
			height: sProps.hei,
			scaleX: sProps.scale,
			scaleY: sProps.scale
		});
		this.stage.container().style.width = sProps.wid + 'px';
		this.stage.container().style.margin = 'auto';
	}

	getScreenProps(winWid, winHei) { //unit test
		let orientation = winWid > winHei ? 0 : 1;
		let aspectRatio = orientation === 0 ? DEF_WIDTH / DEF_HEIGHT : DEF_HEIGHT / DEF_WIDTH;
		//let yMargin = 16;
		let yMargin = 0;
		let	hei = Math.round(winHei - yMargin);//assume window height limits size of canvas
		let	wid = Math.round(hei * aspectRatio);//set width according to height
		if (winWid / winHei < aspectRatio) { //window width limits size of canvas
			wid = winWid;//set height according to width
			hei = Math.round(wid / aspectRatio);
			if (hei + 20 > winHei) {
				wid = winWid - 10;
				hei = Math.round(wid / aspectRatio);
			}
		}
		let scale = orientation === 0 ? wid / DEF_WIDTH : wid / DEF_HEIGHT;
		scale = Math.round(1000 * scale) / 1000;//review this 1000 - maybe 500
		return { orientation, wid, hei, scale };
	}

	/////////////////////////////////////////////////////////////
	//misc

	checkPresetHash() { //unit test
		return null;
	}

	showPointer(show) {
		if (show === 1) {
			this.stage.container().style.cursor = "pointer";
		} else {
			this.stage.container().style.cursor = "default";
		}
	}
}

//for testing
export default Controller;

document.addEventListener("DOMContentLoaded", () => {
	let preloader = new Preloader();
	preloader.startLoad((mainSheet) => {
		if (mainSheet === null) {
			console.log('preloadFailed');
		} else {
			let controller = new Controller();
			controller.init(mainSheet);
			if (preloader.fontLoadComplete) {
				controller.fontsLoaded();
			} else {
				preloader.on('fontsloaded', controller.fontsLoaded);
			}
		}
	});
});
