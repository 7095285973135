import EventEmitter from "eventemitter3";
import { borderRect } from './utils';
import DragBar from './dragbar';
import TickCross from './tickcross';
import BordButton from './bordbutton';

class SettingsPane extends Konva.Group {
	constructor(orientation, mainSheet) {
		super();
		this.state = null;
		this.initialSet = false;
		this.autoDelays = [0.5, 1.5, 2.5, 3.5, 4.5, 5.5];
		this.audioDelays = [0, 1, 2, 3, 4, 5];
		this.textDelays = [0, 1, 2, 3, 4, 5];
		this.eEmitter = new EventEmitter();
		let back = borderRect(510, 430, 12, 60, 48, '#999', '#ccc', '#999', '#fff');
		back.listening(false);

		this.backBut = new BordButton(260, 80, 6, 24, 20, '#777', '#ccc', '#aaa', '#fff', '#ffc');
		this.backBut.addText('Done', 32, "Amaranth", "#444", 24);

		this.autoBut = new Konva.Sprite(mainSheet);
		this.textBut = new Konva.Sprite(mainSheet);
		this.audioBut = new Konva.Sprite(mainSheet);
		this.autoBut.animation('autoIcon');
		this.textBut.animation('textIcon');
		this.audioBut.animation('audioIconBig');
		this.textBut.listening(false);
		this.audioBut.listening(false);
		this.autoBut.listening(false);

		this.autoBut.position({ x: -190, y: -160 });
		this.audioBut.position({ x: -188, y: -34 });
		this.textBut.position({ x: -195, y: 110 });
		this.backBut.position({ x: 0, y: 268 });
		if (orientation === 1) {
			this.backBut.position({ y: 300 });
		}

		//this.add(shade);
		this.add(back, this.backBut);
		this.add(this.autoBut, this.textBut, this.audioBut);

		this.tCross = [];
		this.bars = [];
		this.bText = [];
		for (let i = 0; i < 3; i++) {
			this.bText[i] = new Konva.Text({
				text: '0s', fontSize: 22, fontFamily: "Amaranth", align: "center", fill: "#444"
			});
			this.tCross[i] = new TickCross(i);
			this.bars[i] = new DragBar(i, 6, 20, 60, 8, 140, 20, 8, 100);
			let yPos = -130 + i * 130;
			this.tCross[i].position({ x: -35, y: yPos });
			this.bars[i].position({ x: 95, y: yPos });
			this.bText[i].position({ x: 180, y: yPos - 10 });
			this.bText[i].listening(false);
			this.add(this.tCross[i], this.bars[i], this.bText[i]);
		}
	}

	toggleTickCross(e) {
		this.state[e.currentTarget.ind].active = !this.state[e.currentTarget.ind].active;
		this.updateLine(e.currentTarget.ind);
		this.eEmitter.emit('drawlayer');
	}

	updateLine(ind) {
		this.tCross[ind].set(this.state[ind].active);
		if (this.state[ind].active) {
			this.bars[ind].update(true);
			this.bText[ind].show();
		} else {
			this.bars[ind].update(false);
			this.bText[ind].hide();
		}
	}

	updateLineText(ind) {
		this.bText[ind].text(this.state[ind].delay + 's');
	}

	barDrop(e, f) {
		//////////////////////////////
		//restictions applied here - eg audio delay not more than auto delay
		if (e === 0) this.state[e].delay = this.autoDelays[f];
		if (e === 1) this.state[e].delay = this.audioDelays[f];
		if (e === 2) this.state[e].delay = this.textDelays[f];
		this.updateLineText(e);
		this.eEmitter.emit('drawlayer');
	}

	addLists() {
		for (let i = 0; i < 3; i++) {
			this.tCross[i].on('mouseup touchend', this.toggleTickCross.bind(this));
			this.tCross[i].on('mouseenter', () => { this.eEmitter.emit('showpointer'); });
			this.tCross[i].on('mouseleave', () => { this.eEmitter.emit('unshowpointer'); });
			this.bars[i].bar.on('mouseenter', () => { this.eEmitter.emit('showpointer'); });
			this.bars[i].bar.on('mouseleave', () => { this.eEmitter.emit('unshowpointer'); });
			this.bars[i].eEmitter.on('bardrop', this.barDrop.bind(this));
		}
		this.backBut.on('mouseenter', () => {
		//	this.backBut.showOver();
			this.eEmitter.emit('showpointer');
			this.eEmitter.emit('drawlayer');
		});
		this.backBut.on('mouseleave', () => {
		//	this.backBut.showOut();
			this.eEmitter.emit('unshowpointer');
			this.eEmitter.emit('drawlayer');
		});
		this.backBut.on('mouseup touchend', () => {
		//	this.backBut.showOut();
			this.eEmitter.emit('closepane');
		});
	}

	removeLists() {
		for (let i = 0; i < 3; i++) {
			this.tCross[i].off('mouseup touchend');
			this.tCross[i].off('mouseenter');
			this.tCross[i].off('mouseleave');
			this.bars[i].bar.off('mouseenter');
			this.bars[i].bar.off('mouseleave');
			this.bars[i].eEmitter.off('bardrop');
		}
		this.backBut.off('mouseenter');
		this.backBut.off('mouseleave');
		this.backBut.off('mouseup touchend');
	}

	setState(state) {
		this.state = state;
	}

	initialSetup() {
		this.initialSet = true;
		for (let i = 0; i < 3; i++) {
			this.bars[i].setIntial(this.state[i].delay);
			this.updateLineText(i);
			this.updateLine(i);
		}
	}

	init() {
		if (!this.initialSet) this.initialSetup();
		this.addLists();
		for (let i = 0; i < 3; i++) {
			this.bars[i].init();
		}
	}

	deit() {
		this.removeLists();
		for (let i = 0; i < 3; i++) {
			this.bars[i].deit();
		}
	}
}

export default SettingsPane;
