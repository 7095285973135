import EventEmitter from "eventemitter3";
import WebFont from "./webfont";

const myAnimations = {
	back: [1, 1, 800, 550, 0, 0, 0],
	Loading: [803, 1, 162, 37, 0, -69, -25],
	textIcon: [803, 40, 93, 40, 0, -3, -32],
	audioIcon: [898, 40, 54, 50, 0, -3, -6],
	contentBitMyContentOpen: [803, 82, 86, 84, 0, -7, -8],
	allBits_0001: [891, 92, 82, 82, 0, 0, 0],
	autoIcon: [803, 168, 84, 56, 0, -8, -24],
	allBits_0002: [889, 176, 82, 82, 0, 0, 0],
	allBits_0003: [803, 226, 82, 82, 0, 0, 0],
	allBits_0004: [887, 260, 82, 82, 0, 0, 0],
	allBits_0005: [803, 310, 82, 82, 0, 0, 0],
	allBits_0006: [887, 344, 82, 82, 0, 0, 0],
	allBits_0007: [803, 394, 82, 82, 0, 0, 0],
	allBits_0008: [887, 428, 82, 82, 0, 0, 0],
	allBits_0009: [803, 478, 82, 82, 0, 0, 0],
	allBits_0010: [887, 512, 82, 82, 0, 0, 0],
	allBits_0011: [1, 596, 82, 82, 0, 0, 0],
	LoadFailed: [1, 562, 176, 30, 0, -63, -25],
	allBits_0012: [1, 680, 82, 82, 0, 0, 0],
	allBits_0013: [85, 596, 82, 82, 0, 0, 0],
	allBits_0014: [85, 680, 82, 82, 0, 0, 0],
	allBits_0015: [169, 596, 82, 82, 0, 0, 0],
	allBits_0016: [169, 680, 82, 82, 0, 0, 0],
	SelectContent: [179, 562, 227, 29, 0, -37, -26],
	allBits_0017: [253, 596, 82, 82, 0, 0, 0],
	allBits_0018: [253, 680, 82, 82, 0, 0, 0],
	allBits_0019: [337, 596, 82, 82, 0, 0, 0],
	allBits_0020: [337, 680, 82, 82, 0, 0, 0],
	settingsIcon: [408, 553, 37, 36, 0, -12, -13],
	allBits_0021: [421, 596, 82, 82, 0, 0, 0],
	allBits_0022: [421, 680, 82, 82, 0, 0, 0],
	allBits_0023: [505, 596, 82, 82, 0, 0, 0],
	allBits_0024: [505, 680, 82, 82, 0, 0, 0],
	allBits_0025: [589, 596, 82, 82, 0, 0, 0],
	allBits_0026: [589, 680, 82, 82, 0, 0, 0],
	allBits_0027: [673, 553, 82, 82, 0, 0, 0],
	allBits_0028: [673, 637, 82, 82, 0, 0, 0],
	allBits_0029: [757, 562, 82, 82, 0, 0, 0],
	allBits_0030: [757, 646, 82, 82, 0, 0, 0],
	allBits_0031: [841, 596, 82, 82, 0, 0, 0],
	infoBut: [925, 596, 48, 48, 0, -1, -1],
	audioIconBig: [841, 680, 80, 74, 0, -9, -14]
};

class Preloader extends EventEmitter {
	startLoad(doneCallback) {
		this.fontLoadComplete = false;
		let myImage = new Image();
		myImage.onload = function() {
			const mainSheet = {
				image: myImage,
				animation: "back",
				animations: myAnimations
			};
			doneCallback(mainSheet);
		};
		myImage.onloaderror = function() {
			myImage.src = "";
			doneCallback(null);
		};
		myImage.src = "res/mainSpriteSheet.png";
		this.loadFonts();
	}

	loadFonts() {
		WebFont.load({
			google: {
				families: ["Amaranth:wght@700"]
			},
			active: () => {
				this.fontLoadComplete = true;
				this.emit('fontsloaded');
			}
		});
	}
}

export default Preloader;
