import EventEmitter from "eventemitter3";
import { borderRect } from './utils';
//import { setTitles } from './textcontent';

const ordArr = [
	0, 14, 5, 10, 24, 17, 2, 3,
	1, 15, 6, 11, 8, 18, 26, 19,
	22, 27, 21, 12, 30, 16, 4, 25,
	28, 23, 9, 13, 29, 7, 20
];
const iMargin = 87;
const landStart = [54, 159];
const landCols = 8;
const portStart = [59, 215];
const portCols = 5;

class ContentView extends Konva.Layer {
	constructor(orientation, mainSheet) {
		super();

		this.fontLoadComplete = false;
		let mw = orientation === 0 ? 764 : 512;
		let mh = orientation === 0 ? 474 : 670;
		this.tw = orientation === 0 ? 560 : 420;
		this.th = orientation === 0 ? 134 : 166;
		let ty = orientation === 0 ? 50 : 65;
		this.mainPane = borderRect(mw, mh, 16, 60, 48, '#fff', '#ABC8E2', '#999', '#F1F1F1');
		this.textPane = borderRect(this.tw, this.th, 12, 30, 22, '#ccc', '#FEFDA3', '#bbb', '#fff');
		this.bitsGroup = new Konva.Group();
		this.eEmitter = new EventEmitter();

		this.mainPane.listening(false);
		this.textPane.listening(false);

		this.myTextSprite = new Konva.Sprite(mainSheet);
		this.myText = new Konva.Text({
			fontSize: 36, fontFamily: "Amaranth", fontStyle: 'bold', y: ty, width: this.tw, align: "center", fill: "#444"
		});
		this.textPane.add(this.myTextSprite);
		this.textPane.add(this.myText);

		this.contentBits = [];
		for (let i = 0; i < 31; i++) {
			this.contentBits[i] = new Konva.Sprite(mainSheet);
			this.contentBits[i].ind = i;
			let bInd = i + 1;
			let myAni = bInd < 10 ? 'allBits_000' + bInd : 'allBits_00' + bInd;
			this.contentBits[i].animation(myAni);
		}

		let startPos = orientation === 0 ? landStart : portStart;
		let numCols = orientation === 0 ? landCols : portCols;
		for (let i = 0; i < 31; i++) {
			let xPos = startPos[0] + iMargin * (i % numCols);
			let yPos = startPos[1] + iMargin * (Math.floor(i / numCols));
			if (i > 23 && orientation === 0)xPos += 44;
			this.contentBits[ordArr[i]].position({ x: xPos, y: yPos });
			this.bitsGroup.add(this.contentBits[i]);
		}

		if (orientation === 1) {
			this.contentBits[ordArr[30]].visible(false);
		}

		if (orientation === 0) {
			this.mainPane.position({ x: 400, y: 301 });
			this.textPane.position({ x: 400, y: 77 });
		} else {
			this.mainPane.position({ x: 275, y: 434 });
			this.textPane.position({ x: 275, y: 110 });
		}

		this.add(this.mainPane, this.textPane, this.bitsGroup);
	}

	fontsLoaded() {
		this.fontLoadComplete = true;
		this.myTextSprite.hide();
		this.myText.show();
	}

	showSelect() {
		if (this.fontLoadComplete) {
			this.myText.text('Select Content');
		} else {
			this.setTextAnimation('SelectContent');
		}
		this.draw();
	}

	showLoading() {
		if (this.fontLoadComplete) {
			this.myText.text('Loading ...');
		} else {
			this.setTextAnimation('Loading');
		}
		this.draw();
	}

	showFailed() {
		if (this.fontLoadComplete) {
			this.myText.text('LoadFailed');
		} else {
			this.setTextAnimation('LoadFailed');
		}
		this.draw();
	}

	setTextAnimation(ani) {
		this.myTextSprite.animation(ani);
		this.myTextSprite.position({
			x: this.tw / 2 - this.myTextSprite.attrs.animations[ani][2] / 2,
			y: this.th / 2 - this.myTextSprite.attrs.animations[ani][3] / 2 - 2
		});
	}

	init() {
		this.showSelect();
		this.bitsGroup.on('mouseup touchend', this.bitClick.bind(this));
		this.bitsGroup.on("mouseenter", this.mouseOver.bind(this));
		this.bitsGroup.on("mouseleave", this.mouseOut.bind(this));
	}

	deit() {
		this.bitsGroup.off('mouseup touchend');
		this.bitsGroup.off("mouseenter");
		this.bitsGroup.off("mouseleave");
	}

	bitClick(e) {
		this.deit();
		this.eEmitter.emit("showpointer", 0);
		this.eEmitter.emit('loadset', e.target.ind);
	}

	mouseOver() {
	//	console.log(setTitles[e.target.ind], e.target.ind);
		this.eEmitter.emit("showpointer", 1);
	}

	mouseOut() {
		this.eEmitter.emit("showpointer", 0);
	}
}

export default ContentView;
