import { borderRect } from './utils';
import BordButton from './bordbutton';

class InfoPane extends Konva.Group {
	constructor(orientation) {
		super();
		let back = borderRect(510, 220, 10, 60, 48, '#999', '#ccc', '#999', '#fff');

		this.backBut = new BordButton(260, 80, 6, 24, 20, '#777', '#ccc', '#aaa', '#fff', '#ffc');
		this.backBut.addText('Back', 32, "Amaranth", "#444", 24);

		this.backBut.position({ x: 0, y: 200 });
		if (orientation === 1) {
			this.backBut.position({ y: 270 });
		}

		let myText = new Konva.Text({
			fontSize: 20, fontFamily: "Amaranth", fontStyle: "bold", width: 430, align: 'center', fill: "#444"
		});

		let leText = "Made with Konvajs by Owen Dwyer.\n\n\nContact on\n\ngamestolearnenglish.com/pages/contact/";
		myText.text(leText);

		myText.offsetX(myText.width() / 2);
		myText.offsetY(myText.height() / 2);

		this.add(back, this.backBut, myText);
	}
}

export default InfoPane;
