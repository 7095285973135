class Background extends Konva.Layer {
	constructor(orientation, mainSheet) {
		super();
		this.listening(false);
		let w = orientation === 0 ? 800 : 550;
		let h = orientation === 0 ? 550 : 800;
		let line1 = new Konva.Line({ points: [0, 0, 0, h], stroke: '#aaa', strokeWidth: 1 });
		let line2 = new Konva.Line({ points: [w, 0, w, h], stroke: '#aaa', strokeWidth: 1 });
		let back = new Konva.Sprite(mainSheet);
		let backScale = orientation === 0 ? 1 : 800 / 550;
		backScale = Math.round(backScale * 1000) / 1000;
		let backX = orientation === 0 ? 0 : -300;
		back.animation("back");
		back.position({ x: backX });
		back.scale({ x: backScale, y: backScale });
		this.add(back);
		this.add(line1, line2);
	}
}

export default Background;
