import SettingsPane from "./settingspane";
import InfoPane from "./infopane";
import { borderRect, circleArrow, borderCircle } from './utils';

class VocabView extends Konva.Layer {
	constructor(orientation, mainSheet) {
		super();

		this.myIm = null;

		this.lArrow = circleArrow(46, 6, '#999', '#ccc', '#aaa', '#fff');
		this.rArrow = circleArrow(46, 6, '#999', '#ccc', '#aaa', '#fff');
		this.rArrow.delt = 1;
		this.lArrow.delt = -1;
		this.lArrow.cache();
		this.rArrow.cache();

		this.topBut = borderRect(88, 88, 6, 20, 16, '#999', '#ccc', '#aaa', '#fff');
		let mRect = new Konva.Rect({ x: 26, y: 26, width: 36, height: 36, fill: '#555', cornerRadius: 8 });
		mRect.listening(false);
		this.topBut.add(mRect);
		this.topBut.cache();

		this.audioBut = borderRect(88, 88, 6, 20, 16, '#999', '#ccc', '#aaa', '#fff');
		let playAudioBut = new Konva.Sprite(mainSheet);
		playAudioBut.listening(false);
		playAudioBut.animation("audioIcon");
		playAudioBut.position({ x: 18, y: 20 });
		this.audioBut.add(playAudioBut);
		this.audioBut.cache();

		this.backPane = borderRect(370, 370, 10, 60, 52, '#777', '#ccc', '#aaa', '#fff');
		this.imGroup = new Konva.Group();
		this.textPane = new Konva.Group();
		this.backPane.listening(false);
		this.imGroup.listening(false);
		this.textPane.listening(false);

		let tw = orientation === 0 ? 600 : 520;
		let th = orientation === 0 ? 90 : 110;
		let fSize = orientation === 0 ? 44 : 48;
		let tBack = borderRect(tw, th, 6, 44, 40, '#777', '#ccc', '#aaa', '#fff');
		this.maxTextWidth = tw - 80;
		this.myText = new Konva.Text({
			fontSize: fSize, fontFamily: "Amaranth", fontStyle: "bold", fill: "#444"
		});
		this.textPane.add(tBack);
		this.textPane.add(this.myText);

		this.allButtons = new Konva.Group();

		this.settingsBut = new Konva.Group();
		let settingsIcon = new Konva.Sprite(mainSheet);
		settingsIcon.listening(false);
		settingsIcon.animation('settingsIcon');
		settingsIcon.position({ x: -18, y: -18 });
		let settingsBack = borderCircle(30, 5, '#999', '#ccc', '#aaa', '#fff');
		this.settingsBut.add(settingsBack, settingsIcon);
		this.settingsBut.cache();

		this.infoBut = new Konva.Sprite(mainSheet);
		this.infoBut.animation('infoBut');

		this.settingsPane = new SettingsPane(orientation, mainSheet);
		this.infoPane = new InfoPane(orientation);

		this.allButtons.add(this.topBut, this.audioBut, this.settingsBut, this.infoBut, this.lArrow, this.rArrow);
		this.add(this.backPane, this.imGroup, this.textPane, this.allButtons);

		if (orientation === 0) {
			this.backPane.position({ x: 400, y: 200 });
			this.textPane.position({ x: 400, y: 390 });
			this.imGroup.position({ x: 400, y: 190 });
			this.infoBut.position({ x: 4, y: 500 });
			this.settingsBut.position({ x: 766, y: 516 });
			this.topBut.position({ x: 354, y: 490 });
			this.audioBut.position({ x: 446, y: 490 });
			this.lArrow.position({ x: 260, y: 490 });
			this.rArrow.position({ x: 540, y: 490 });
			this.settingsPane.position({ x: 400, y: 230 });
			this.infoPane.position({ x: 400, y: 230 });

			this.lArrow.scale({ x: -1 });
		} else {
			this.backPane.position({ x: 275, y: 240 });
			this.imGroup.position({ x: 275, y: 230 });
			this.textPane.position({ x: 275, y: 501 });
			this.infoBut.position({ x: 4, y: 748 });
			this.settingsBut.position({ x: 516, y: 766 });

			this.topBut.scale({ x: 1.2, y: 1.2 });
			this.audioBut.scale({ x: 1.2, y: 1.2 });
			this.lArrow.scale({ x: -1.2, y: 1.2 });//note this minus value
			this.rArrow.scale({ x: 1.2, y: 1.2 });

			this.topBut.position({ x: 218, y: 630 });
			this.audioBut.position({ x: 332, y: 630 });
			this.lArrow.position({ x: 102, y: 630 });
			this.rArrow.position({ x: 448, y: 630 });
			this.settingsPane.position({ x: 275, y: 360 });
			this.infoPane.position({ x: 275, y: 360 });
		}
	}

	setSprite(contentSheet) {
		this.clearSprite();
		this.myIm = new Konva.Sprite(contentSheet);
		this.imGroup.add(this.myIm);
	}

	clearSprite() {
		if (this.myIm !== null) {
			this.myIm.remove();
			this.myIm.destroy();
			this.myIm = null;
		}
	}

	showIm(itemInd) {
		this.myIm.animation(itemInd);
		let xPos = -150 - this.myIm.attrs.animations[itemInd][5];
		let yPos = -150 - this.myIm.attrs.animations[itemInd][6];
		this.myIm.position({ x: xPos, y: yPos });
	}

	showText(txt) {
		//txt = "spaghetti bolognese";
		this.myText.text(txt);
		this.myText.scale({ x: 1, y: 1 });
		let tScale = this.maxTextWidth / this.myText.textWidth;
		if (tScale < 1) {
			this.myText.scale({ x: tScale, y: tScale });
		}
		this.myText.offsetX(this.myText.width() / 2);
		this.myText.offsetY(this.myText.height() / 2);
	}

	clearMain() {
		this.backPane.hide();
		this.textPane.hide();
		this.imGroup.hide();
		this.infoBut.hide();
		this.settingsBut.hide();
		this.topBut.hide();
		this.audioBut.hide();
		this.lArrow.hide();
		this.rArrow.hide();
	}

	restoreMain() {
		this.backPane.show();
		this.textPane.show();
		this.imGroup.show();
		this.infoBut.show();
		this.settingsBut.show();
		this.topBut.show();
		this.audioBut.show();
		this.lArrow.show();
		this.rArrow.show();
	}

	showSettings() {
		this.add(this.settingsPane);
		this.clearMain();
	}

	unshowSettings() {
		this.settingsPane.remove();
		this.restoreMain();
	}

	showInfo() {
		this.add(this.infoPane);
		this.clearMain();
	}

	unshowInfo() {
		this.infoPane.remove();
		this.restoreMain();
	}
}

export default VocabView;
