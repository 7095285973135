import EventEmitter from "eventemitter3";
import VocabView from "./vocabview";

class VocabController {
	constructor(orientation, mainSheet) {
		this.view = new VocabView(orientation, mainSheet);
		this.eEmitter = new EventEmitter();

		this.audioTimer = null;
		this.autoTimer = null;
		this.textTimer = null;

		this.textArray = [];
		this.setLen = 0;
		this.itemInd = 0;
		this.myAudio = null;
		this.state = [
			{ active: false, delay: 2.5 }, //auto
			{ active: false, delay: 0 }, //audio
			{ active: true, delay: 0 }//text
		];
		this.view.settingsPane.setState(this.state);
	}

	init(contentSheet, textArray) {
		this.itemInd = 0;
		this.textArray = textArray;
		this.setLen = this.textArray.length;
		this.view.setSprite(contentSheet);
		this.addLists();
		this.toggleAudioButVisible();
		this.showItem();
	}

	deit() {
		this.view.clearSprite();
		this.removeLists();
		if (this.myAudio !== null) this.myAudio.unload();
		this.myAudio = null;
		this.clearDelays();
	}

	setAudio(myAudio) {
		this.myAudio = myAudio;
		this.toggleAudioButVisible();
		this.drawLayer();
	}

	toggleAudioButVisible() {
		if (this.myAudio === null) {
			this.view.audioBut.opacity(0.3);
		} else {
			this.view.audioBut.opacity(1);
		}
	}

	clearDelays() {
		clearTimeout(this.audioTimer);
		clearTimeout(this.autoTimer);
		clearTimeout(this.textTimer);
	}

	showItem() {
		this.clearDelays();
		this.view.showIm(this.itemInd);
		if (this.state[0].active) {
			this.autoTimer = setTimeout(this.nextFun.bind(this), this.state[0].delay * 1000);
		}
		if (this.state[1].active) {
			this.audioTimer = setTimeout(this.playAudio.bind(this), this.state[1].delay * 1000);
		}
		if (this.state[2].active) {
			if (this.state[2].delay > 0) {
				this.view.showText("");
				this.textTimer = setTimeout(() => {
					this.view.showText(this.textArray[this.itemInd]);
					this.drawLayer();
				}, this.state[2].delay * 1000);
			} else {
				this.view.showText(this.textArray[this.itemInd]);
			}
		}
		this.drawLayer();
	}

	playAudio() {
		if (this.myAudio !== null) {
			let sStr = this.itemInd.toString();
			this.myAudio.stop();
			this.myAudio.play(sStr);
		}
	}

	nextFun() {
		this.itemInd += 1;
		if (this.itemInd >= this.setLen) this.itemInd = 0;
		if (this.itemInd < 0) this.itemInd = this.setLen - 1;
		this.showItem();
	}

	arrowClick(e) {
		this.itemInd += e.currentTarget.delt;
		if (this.itemInd >= this.setLen) this.itemInd = 0;
		if (this.itemInd < 0) this.itemInd = this.setLen - 1;
		this.showItem();
	}

	settingsClick() {
		this.clearDelays();
		this.view.showSettings();
		this.view.settingsPane.init();
		this.drawLayer();
	}

	settingsClose() {
		this.resume();
		this.view.unshowSettings();
		this.view.settingsPane.deit();
		this.drawLayer();
	}

	resume() {
		if (this.state[2].active) {
			this.view.showText(this.textArray[this.itemInd]);
		} else {
			this.view.showText("");
		}
		if (this.state[0].active) {
			this.autoTimer = setTimeout(this.nextFun.bind(this), this.state[0].delay * 1000);
		}
	}

	infoClick() {
		this.clearDelays();
		this.view.showInfo();
		this.drawLayer();
	}

	infoClose() {
		this.resume();
		this.view.unshowInfo();
		this.drawLayer();
	}

	drawLayer() {
		this.view.draw();
	}

	showPointer() {	this.eEmitter.emit("showpointer", 1); }

	unshowPointer() {	this.eEmitter.emit("showpointer", 0); }

	addLists() {
		this.view.settingsBut.on("mouseup touchend", this.settingsClick.bind(this));
		this.view.infoBut.on("mouseup touchend", this.infoClick.bind(this));

		this.view.infoPane.backBut.on("mouseup touchend", this.infoClose.bind(this));
		this.view.infoPane.backBut.on("mouseenter", this.showPointer.bind(this));
		this.view.infoPane.backBut.on("mouseleave", this.unshowPointer.bind(this));

		this.view.audioBut.on("mouseup touchend", this.playAudio.bind(this));
		this.view.topBut.on("mouseup touchend", () => { this.eEmitter.emit("exitclick"); });
		this.view.lArrow.on("mouseup touchend", this.arrowClick.bind(this));
		this.view.rArrow.on("mouseup touchend", this.arrowClick.bind(this));
		this.view.allButtons.on("mouseenter", this.showPointer.bind(this));
		this.view.allButtons.on("mouseleave", this.unshowPointer.bind(this));

		this.view.settingsPane.eEmitter.on("closepane", this.settingsClose.bind(this));
		this.view.settingsPane.eEmitter.on('drawlayer', this.drawLayer.bind(this));
		this.view.settingsPane.eEmitter.on('showpointer', this.showPointer.bind(this));
		this.view.settingsPane.eEmitter.on('unshowpointer', this.unshowPointer.bind(this));
	}

	removeLists() {
		this.view.settingsBut.off("mouseup touchend");
		this.view.infoBut.off("mouseup touchend");

		this.view.infoPane.backBut.off("mouseup touchend");
		this.view.infoPane.backBut.off("mouseenter");
		this.view.infoPane.backBut.off("mouseleave");

		this.view.audioBut.off("mouseup touchend");
		this.view.topBut.off("mouseup touchend");
		this.view.lArrow.off("mouseup touchend");
		this.view.rArrow.off("mouseup touchend");
		this.view.allButtons.off("mouseenter");
		this.view.allButtons.off("mouseleave");

		this.view.settingsPane.eEmitter.off("closepane");
		this.view.settingsPane.eEmitter.off('drawlayer');
		this.view.settingsPane.eEmitter.off('showpointer');
		this.view.settingsPane.eEmitter.off('unshowpointer');
	}
}

export default VocabController;
